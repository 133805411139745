.view-blog .lighthouse-jump-nav-container {
  padding: 0;
}

.view-blog .view-display-id-attachment_1,
.view-blog .view-display-id-attachment_3 {
  padding-left: 0;
  @include media-breakpoint-up(lg) {
    width: 100%;
  }
  @include media-breakpoint-up(xl) {
    float: left;
    width: 70%;
  }
}

.view-blog .view-display-id-attachment_2,
.view-blog .view-display-id-attachment_4 {
  @include media-breakpoint-up(xl) {
    float: left;
    width: 30%;
  }
}

.view-blog .view-display-id-attachment_2 .card-teaser-body,
.view-blog .view-display-id-attachment_4 .card-teaser-body  {
  @include media-breakpoint-up(xl) {
    display: none;
  }
}

.view-blog .view-display-id-attachment_1 .views-row,
.view-blog .view-display-id-attachment_3 .views-row {
  @include media-breakpoint-up(md) {
    width: 100%;
  }
  @include media-breakpoint-up(xl) {
    height: 62rem;
  }
}

.view-blog .view-display-id-attachment_2 .views-row,
.view-blog .view-display-id-attachment_4 .views-row {
  @include media-breakpoint-up(xl) {
    height: 30rem;
    margin-bottom: 2rem;
  }
}

.view-blog .view-display-id-attachment_1.view-block-card,
.view-blog .view-display-id-attachment_2.view-block-card,
.view-blog .view-display-id-attachment_3.view-block-card,
.view-blog .view-display-id-attachment_4.view-block-card {
  padding: 0;
}

.view-blog .view-display-id-attachment_2.view-block-card,
.view-blog .view-display-id-attachment_4.view-block-card {
  @include media-breakpoint-up(xl) {
    padding-left: 2.4rem;
  }
}

.page-taxonomy-term .region-content > .view-blog > .view-content,
.page-blogs .region-content > .view-blog > .view-content {
  clear: both;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
