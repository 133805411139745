// Begin body styles.
.node-type-structured-content .field-name-body {
  @include extend-container();
  @include media-breakpoint-up(md) {
    max-width: 720px;
    margin: 0 auto;
    font-family: $font-family-halis;
  }
}

.node-type-structured-content .field-name-body h2 {
  margin-bottom: 2rem;
}

.node-type-structured-content .field-name-body p {
  font-size: 2.4rem;
  font-weight: 300;
}
// End body styles.

// Begin group image bar styles.
.group-image-bar {
  margin-bottom: 10rem;
  background-color: $brand-dark-blue-2;
  @include media-breakpoint-only(md) {
    padding: 4rem;
  }
  @include media-breakpoint-up(lg) {
    display: flex;
    align-items: center;
  }
}
.image-bar--image-full-width .group-image-bar {
  position: relative;
  overflow: hidden;
  padding-top: 2em;
  padding-bottom: 2em;
  @include media-breakpoint-up(lg) {
    flex-direction: row-reverse;
  }
  .field-name-field-long-text {
    @include media-breakpoint-up(lg) {
      text-align: right!important;
    }
  }
  h3 {
    color: #373536;
  }
  .field-name-field-image {
    position: absolute;
    :after {
      content: '';
      background: rgba(255, 255, 255, 0.5);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
  .group-image-bar-content-wrapper {
    position: relative;
    z-index: 10;
    width: 100%!important;
  }
  .group-image-bar-content-wrapper {
    color: #373536;
    font-weight: 400;
  }
}

.image-bar--image-left-text-right,
.image-bar--image-right-text-left {
  .field-name-field-image {
    @include media-breakpoint-up(lg) {
      width: 50%;
    }
  }
}
.image-bar--full-width {
  .field-name-field-image {
    @include media-breakpoint-up(lg) {
      width: 100%;
    }
  }
}
.group-image-bar .field-name-field-image {
  @include media-breakpoint-only(md) {
    text-align: center;
  }
}

// Specific to structured content type.
.node-type-structured-content .group-image-bar .field-name-field-long-text {
  padding: 4rem;
  text-align: center;
  font-family: $font-family-halis;
  font-size: 2rem;
  font-weight: 300;
  line-height: 1.25;
  color: #fff;
  @include media-breakpoint-only(md) {
    width: 720px;
    margin: 0 auto;
    font-size: 2.2rem;
  }
  @include media-breakpoint-up(lg) {
    width: 50%;
    text-align: left;
  }
  @include media-breakpoint-up(xl) {
    font-size: 2.4rem;
    line-height: 1.375;
  }
}

// Specific to structured content type.
.node-type-structured-content .group-image-bar .field-name-field-long-text .field-items {
  @include media-breakpoint-up(xl) {
    width: 100%;
    min-width: 54rem;
    max-width: 64rem;
    padding-left: 5rem;
  }
}

.group-image-bar .field-name-field-image img {
  width: 100%;
  height: auto;
  @include media-breakpoint-only(md) {
    width: 720px;
  }
}

.field-name-field-workshop-images {
  text-align: center;
}

.field-name-field-workshop-images img {
  width: 100%;
  max-width: 200rem;
  height: auto;
  margin: 0 auto;
}
// End group image bar styles.
