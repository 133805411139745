.btn {
  padding-left: 3rem;
  padding-right: 3rem;
  border-width: 4px;
  border-style: solid;
  font-size: 2rem;
  border-radius: 3rem;
  font-family: $font-family-halis;
  line-height: 2.2;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  transition: all .3s ease-in-out;
}

.btn-primary {
  border-color: $brand-blue;
  &:hover {
    border-color: $brand-dark-blue-2;
  }
}

.btn-ghost-blue {
  border: 4px solid $brand-blue;
  color: $brand-blue;
  &:hover {
    border-color: $brand-dark-blue-2;
  }
}

.btn-orange {
  background-color: $brand-orange;
  color: #fff;
  &:hover {
    color: #fff;
    background-color: rgba($brand-orange, 0.8);
  }
}

.btn-white {
  background-color: #fff;
  &:hover {
    background-color: rgba(#fff, 0.8);
    color: $brand-blue;
  }
}

.btn-ghost-white {
  background-color: transparent;
  border: 4px solid #fff;
  color: #fff;
  &:hover {
    border-color: rgba(#fff, 0.8);
    color: rgba(#fff, 0.8);
  }
}

.btn-remove {
  padding-top: 1.1rem;
  padding-bottom: 1.1rem;
  border: 1px solid $brand-blue;
  font-size: 1.6rem;
  text-transform: none;
  color: $brand-text-gray;
  &:after {
    @extend %icon;
    content: "\e900";
    margin-left: 3rem;
    font-size: 1.2rem;
  }
  &:hover {
    color: rgba($brand-text-gray, 0.8);
    border-color: $brand-dark-blue-2;
  }
}

.form-submit {
  @extend .btn-primary;
}

.link-arrow {
  &:after {
    @extend %icon;
    content: '\e90c';
    position: relative;
    font-size: 1.4rem;
    left: 1rem;
    transition: left .3s ease-in-out;
  }
  &:hover {
    text-decoration: none;
    &:after {
      left: 2rem;
    }
  }
}
