.group-testimonial-wrapper {
  background: url(../images/testimonials-bg.jpg) no-repeat;
  background-size: cover;
  @include extend-container-full-width();
}

.group-testimonial-wrapper .field-name-field-testimonial {
  @include extend-container();
  padding-top: 10rem;
  padding-bottom: 10rem;
  text-align: center;
  font-size: 2rem;
  line-height: 1.25;
  @include media-breakpoint-up(lg) {
    font-size: 3rem;
  }
  @include media-breakpoint-up(xl) {
    max-width: 800px;
  }
}

.group-testimonial-wrapper .field-name-field-title {
  font-size: 4rem;
  margin-bottom: 3rem;
  &:before {
    @extend %icon;
    content: '\e910';
    display: block;
    margin-bottom: 3rem;
    font-size: 3rem;
  }
}

.group-testimonial-wrapper .field-name-field-additional-text {
  margin-top: 3rem;
}
