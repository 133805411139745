// Main Menu
.menu-icon {
  float: right;
  border: 0;
  background: none;
  &:before {
    @extend %icon;
    content: '\e90d';
    color: $gray;
  }
  @include media-breakpoint-up(md) {
    display: none;
  }
}

.header {
  transition: all .3s ease-in-out;
}

@include media-breakpoint-down(sm) {

  .mobile-menu-active .block-nice-menus {
    display: block;
  }

  .mobile-menu-active .menu-icon {
    &:before {
      @include icon;
      content: '\e900';
      color: #fff;
    }
  }

  .mobile-menu-active {
    height: 100vh;
    overflow: hidden;
  }

  .mobile-menu-active .header {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    border-top: 0;
    z-index: 9999;
    background-color: $gray;
  }

  .mobile-menu-active .header-inner {
    position: static;
  }

  .mobile-menu-active .logo {
    display: none;
  }

  #block-menu-menu-social-media-menu {
    display: none;
  }

  .mobile-menu-active #block-menu-menu-social-media-menu {
    display: block;
    position: absolute;
    z-index: 99999;
    top: 17px;
    left: 32px;
    right: 200px;
    bottom: auto;
    text-align: left;
    margin: 0;
    ul {
      margin: 0;
      padding: 0;
      display: flex;
      li {
        float: none;
      }
    }
  }

  .mobile-menu-active #block-menu-menu-social-media-menu li a {
    &:before {
      font-size: 1.8rem;
      width: 3.6rem;
      height: 3.6rem;
    }
  }

  .mobile-menu-active #block-search-form {
    display: block;
    position: absolute;
    top: auto;
    bottom: 1rem;
    left: 50%;
    width: 30rem;
    margin-left: -15rem;
    z-index: 99999;
  }

  .mobile-menu-active .block-search .form-type-textfield {
    display: inline-block;
    width: 25rem;
  }

  .mobile-menu-active .block-search .form-text {
    width: 100%;
    height: 3.8rem;
    border: 2px solid #fff;
    background-color: transparent;
    color: #fff;
  }

  .mobile-menu-active .block-search .form-submit {
    float: right;
    border-color: #fff;
    background: url(../images/search-white.png) no-repeat 50% 48%;
  }

  .mobile-menu-active .block-nice-menus {
    position: relative;
    top: 4rem;
    z-index: 99999;
  }

  .mobile-menu-active .block-nice-menus > .content{
    display: flex;
    align-items: center;
    height: calc(100vh - 150px);
  }

  // mobile navigation
  .mobile-menu-active .block-nice-menus a {
    margin-top: .2rem;
    margin-bottom: .2rem;
    text-transform: uppercase;
    font-family: $font-family-halis;
    font-weight: 600;
    color: #fff;
    &:hover {
      text-decoration: none;
    }
  }

  .mobile-menu-active ul.nice-menu > li {
    overflow: hidden !important;
  }

  .mobile-menu-active ul.nice-menu-down li.menuparent {
    background: 0;
  }

  .mobile-menu-active ul.nice-menu {
    float: none;
    width: 80%;
    margin: 0 auto;
    text-align: center;
  }

  .mobile-menu-active ul.nice-menu li {
    float: none;
    border: 0;
    background: 0;
  }

  .mobile-menu-active ul.nice-menu ul {
    border-top: 0;
  }

  .mobile-menu-active ul.nice-menu-down .menuparent a {
    padding-right: 0;
  }

}
