// Mixins
@mixin form-border-white {
  input.form-text {
    border-color: #fff;
  }
  .form-select {
    border-color: #fff;
  }
  .select-style {
    background: #fff url("../images/select-control-blue-sized.png") no-repeat 100% 50%;
  }
  .form-textarea {
    border-color: #fff;
  }
  .form-submit {
    @extend .btn-primary;
  }
}

@mixin radio-buttons() {
  .form-type-radio input {
    display: none;
  }
  .form-type-radio label.checked {
    background-color: $brand-blue;
    color: #fff;
    cursor: pointer;
  }
  .form-type-radio label {
    @extend .btn;
    border: 2px solid $brand-blue;
    color: $brand-blue;
  }
}

@mixin custom-radio() {
  .form-type-radio input {
    display: none;
  }
  .form-type-radio label {
    &:before {
      @extend %icon;
      content: '\e90b';
      position: relative;
      display: inline-block;
      top: 0.2rem;
      margin-right: 1.4rem;
      color: $brand-primary;
    }
  }
  .form-type-radio label.checked {
    &:before {
      @extend %icon;
      content: '\e90a';
      color: $brand-primary;
    }
  }
}

@mixin custom-checkbox() {
  .form-type-checkbox input,
  .webform-component-boolean input {
    display: none;
  }
  .form-type-checkbox label,
  .webform-component-boolean label {
    &:before {
      @extend %icon;
      font-family: 'IcoMoon-Free-New'!important;
      content: '\e908';
      position: relative;
      display: inline-block;
      top: 0.2rem;
      margin-right: 1.4rem;
      color: $brand-primary;
    }
  }
  .form-type-checkbox label.checked,
  .webform-component-boolean label.checked {
    &:before {
      @extend %icon;
      font-family: 'IcoMoon-Free-New'!important;
      content: '\e909';
      color: $brand-primary;
    }
  }
}

.node-webform {
  font-family: "Halis R",Helvetica,Arial,sans-serif;
  @include media-breakpoint-up(xl) {
    width: 50%;
    margin: 0 auto;
  }
}

// Custom select styles (dependendent on wrapper with .select-style class).
.select-style {
    padding: 0;
    margin: 0;
    width: 100%;
    border-radius: 0.4rem;
    overflow: hidden;
    background-color: #fff;
    background: #fff url("../images/select-control-gray-sized.png") no-repeat 100% 50%;
    border: 1px solid $brand-blue;
}

.select-style select {
    padding: 5px 8px;
    width: 130%;
    border: none;
    box-shadow: none;
    background-color: transparent;
    background-image: none;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
}

.select-style select:focus {
    outline: none;
}

form {
  font-family: $font-family-halis;
}

// Using bootstrap screen reader only class to hide form labels by default.
label {
  @extend .sr-only;
}

input.form-text {
  height: 4.4rem;
  border-radius: .4rem;
  border-color: $brand-blue;
}

.form-select {
  height: 4.4rem;
  border-radius: .4rem;
  border-color: $brand-blue;
}

.form-textarea {
  border-radius: .4rem;
  border-color: $brand-blue;
}

.webform-component-textarea,
.form-textarea  {
  margin: 4rem 0;
}

.form-type-radio,
.form-type-radios,
.webform-component-radios,
.form-type-checkbox,
.webform-component-checkboxes,
.webform-component-boolean {
  margin: 4rem 0;
}

.form-type-radio label,
.form-type-radios > label,
.webform-component-radios > label,
.form-type-checkbox label,
.webform-component-checkboxes > label,
.webform-component-boolean > label,
.webform-component-file > label,
.views-exposed-widget > label,
.form-type-select > label {
  @extend %sr-only-override;
}

// Call radio button styles.
.btn-radio {
  @include radio-buttons();
}

// Call custom radio styles.
.custom-radio {
  @include custom-radio();
}

// Call custom checkbox styles.
@include custom-checkbox();

.form-managed-file .form-file {
  height: 4.4rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
  padding: 1rem;
  border: 1px solid $brand-blue;
  border-radius: 0.4rem;
  font-size: 1.6rem;
  line-height: 1;
  @include media-breakpoint-up(sm) {
    width: 34rem;
    margin-bottom: 0;
  }
}

.form-managed-file .form-submit {
  height: 4.4rem;
  padding-top: 0;
  padding-bottom: 0;
  line-height: 1;
}

.webform-component .description {
  margin-top: 1rem;
  font-size: 1.4rem;
  line-height: 1.25;
}

.fieldset-legend {
  font-size: 2rem;
  font-weight: bold;
}

// Style used to help create styleguide
.bg-blue {
  background-color: $brand-blue;
  padding: 4rem;
}

.bg-light-gray {
  background-color: $gray-lighter;
  padding: 4rem;
  @include form-border-white();
}

.form-type-checkbox input {
  display: none;
}
.form-type-checkbox label {
  &:before {
    @extend %icon;
    content: '\e908';
    color: $brand-primary;
  }
}
.form-type-checkbox label.checked {
  &:before {
    @extend %icon;
    content: '\e909';
    color: $brand-primary;
  }
}
input.password-confirm,
input.password-field {
  width: 100%;
}

.captcha {
  margin-block: 2rem;
  legend.panel-heading {
    display: none;
  }
}
