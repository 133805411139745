.comment-wrapper {
  border-top: 1px solid #979797;
}

.comment-wrapper h2 {
  margin: 5rem 0;
}

.comment-wrapper-layout {
  margin-bottom: 6rem;
  border-bottom: 1px solid #979797;
  @include media-breakpoint-up(lg) {
    display: flex;
  }
}

.comment-wrapper-image {
  text-align: center;
  margin-bottom: 3rem;
  @include media-breakpoint-up(lg) {
    text-align: left;
    padding-right: 3rem;
  }
}

.comment-profile-image {
  width: 8.5rem;
  height: auto;
  border-radius: 50%;
}


.comment-wrapper-text h3 {
  margin-bottom: 2rem;
  font-size: 2.6rem;
  line-height: 1.125;
}

.comment-wrapper-text h3 a {
  color: $gray-dark;
}

.comment-wrapper-text .submitted {
  font-family: $font-family-halis;
  font-size: 1.8rem;
  text-transform: uppercase;
  font-weight: 500;
}

.comment-wrapper-text .links {
  padding: 0;
  list-style-type: none;
}

.comment-wrapper-text .links li {
  display: inline-block;
  margin-right: 2rem;
}
