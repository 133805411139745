.region-main-prefix {
  font-size: 0;
}

.block-nice-menus {
  display: none;
}

@include media-breakpoint-up(md) {

  .block-nice-menus {
    display: inline-block;
    width: 100%;
    background-color: $gray;
    font-family: $font-family-halis;
  }
  ul.nice-menu {
    @include extend-container();
    float: none;
    display: flex;
  }
  ul.nice-menu a {
    text-transform: uppercase;
    &:hover {
      text-decoration: none;
    }
    @include media-breakpoint-up(md) {
      font-size: 1.5rem;
    }
    @include media-breakpoint-up(xl) {
      font-size: 2rem;
    }
  }
  ul.nice-menu-down li.menuparent {
    background: 0;
  }
  ul.nice-menu > li {
    flex: 1;
    border: 0;
    border-left: 2px solid #fff;
    text-align: center;
    background: 0;
    &:last-child {
      border-right: 2px solid #fff;
    }
  }
  .block-nice-menus ul.nice-menu > li > a {
    font-weight: 600;
    color: #fff;
    padding-left: 5px;
    padding-right: 5px;
  }
  ul.nice-menu-down ul {
    top: 3.9rem;

    left: -2px;
    border: 2px solid $gray-light;
    background: #fff;
    @include media-breakpoint-up(lg) {
      top: 4rem;
    }
  }
  ul.nice-menu-down ul li {
    border-left: 0;
    border-right: 0;
    text-align: left;
    background: #fff;
    &:last-child {
      border-bottom: 0;
    }
  }
  ul.nice-menu-down ul li a {
    padding-right: 2rem;
    padding-left: 2rem;
    // font-size: 1.9rem;
    font-weight: 300;
    color: $gray;
    &:hover {
      background-color: $gray-lightest;
    }
  }
  ul.nice-menu-down li.menuparent {
    &:hover {
      background: none;
      background-color: $brand-blue;
      text-decoration: none;
    }
  }
}
