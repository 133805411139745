// Variables - Buttons 
//
// define / override bootstrap variables for base elements

// Links
//
// Style anchor elements.

$link-color:                 $brand-primary;
$link-decoration:            none;
$link-hover-color:           darken($link-color, 15%);
$link-hover-decoration:      underline;

// Buttons
//
// For each of Bootstrap's buttons, define text, background and border color.

$btn-padding-x:                  1rem;
$btn-padding-y:                  .375rem;
$btn-font-weight:                normal;

$btn-primary-color:              #fff;
$btn-primary-bg:                 $brand-primary;
$btn-primary-border:             $btn-primary-bg;

$btn-secondary-color:            $gray-dark;
$btn-secondary-bg:               #fff;
$btn-secondary-border:           #ccc;

$btn-info-color:                 #fff;
$btn-info-bg:                    $brand-info;
$btn-info-border:                $btn-info-bg;

$btn-success-color:              #fff;
$btn-success-bg:                 $brand-success;
$btn-success-border:             $btn-success-bg;

$btn-warning-color:              #fff;
$btn-warning-bg:                 $brand-warning;
$btn-warning-border:             $btn-warning-bg;

$btn-danger-color:               #fff;
$btn-danger-bg:                  $brand-danger;
$btn-danger-border:              $btn-danger-bg;

$btn-link-disabled-color:        $gray-light;

$btn-padding-x-sm:               .75rem;
$btn-padding-y-sm:               .25rem;

$btn-padding-x-lg:               1.25rem;
$btn-padding-y-lg:               .75rem;

// Allows for customizing button radius independently from global border radius
$btn-border-radius:              $border-radius;
$btn-border-radius-lg:           $border-radius-lg;
$btn-border-radius-sm:           $border-radius-sm;
