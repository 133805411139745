// Styleguide - Bootstrap navigational elements.

// Dropdowns
//
// Dropdown menu container and contents.

$dropdown-bg:                    #fff;
$dropdown-border:                rgba(0,0,0,.15);
$dropdown-divider-bg:            #e5e5e5;

$dropdown-link-color:            $gray-dark;
$dropdown-link-hover-color:      darken($gray-dark, 5%);
$dropdown-link-hover-bg:         #f5f5f5;

$dropdown-link-active-color:     $component-active-color;
$dropdown-link-active-bg:        $component-active-bg;

$dropdown-link-disabled-color:   $gray-light;

$dropdown-header-color:          $gray-light;

// Navbar

$navbar-border-radius:              $border-radius;
$navbar-padding-horizontal:         $spacer;
$navbar-padding-vertical:           ($spacer / 2);

$navbar-dark-color:                 rgba(255,255,255,.5);
$navbar-dark-hover-color:           rgba(255,255,255,.75);
$navbar-dark-active-color:          rgba(255,255,255,1);
$navbar-dark-disabled-color:        rgba(255,255,255,.25);

$navbar-light-color:                rgba(0,0,0,.3);
$navbar-light-hover-color:          rgba(0,0,0,.6);
$navbar-light-active-color:         rgba(0,0,0,.8);
$navbar-light-disabled-color:       rgba(0,0,0,.15);


// Navs

$nav-link-padding:                          .5em 1em;
$nav-link-hover-bg:                         $gray-lighter;

$nav-disabled-link-color:                   $gray-light;
$nav-disabled-link-hover-color:             $gray-light;

$nav-tabs-border-color:                     #ddd;

$nav-tabs-link-hover-border-color:          $gray-lighter;

$nav-tabs-active-link-hover-bg:             $body-bg;
$nav-tabs-active-link-hover-color:          $gray;
$nav-tabs-active-link-hover-border-color:   #ddd;

$nav-tabs-justified-link-border-color:            #ddd;
$nav-tabs-justified-active-link-border-color:     $body-bg;

$nav-pills-border-radius:                   $border-radius;
$nav-pills-active-link-hover-bg:            $component-active-bg;
$nav-pills-active-link-hover-color:         $component-active-color;


// Pagination

$pagination-padding-x:                .75rem;
$pagination-padding-y:                .5rem;
$pagination-padding-x-sm:             .75rem;
$pagination-padding-y-sm:             .275rem;
$pagination-padding-x-lg:             1.5rem;
$pagination-padding-y-lg:             .75rem;


$pagination-color:                     $link-color;
$pagination-bg:                        #fff;
$pagination-border:                    #ddd;

$pagination-hover-color:               $link-hover-color;
$pagination-hover-bg:                  $gray-lighter;
$pagination-hover-border:              #ddd;

$pagination-active-color:              #fff;
$pagination-active-bg:                 $brand-primary;
$pagination-active-border:             $brand-primary;

$pagination-disabled-color:            $gray-light;
$pagination-disabled-bg:               #fff;
$pagination-disabled-border:           #ddd;


// Pager

$pager-bg:                             $pagination-bg;
$pager-border:                         $pagination-border;
$pager-border-radius:                  15px;

$pager-hover-bg:                       $pagination-hover-bg;

$pager-active-bg:                      $pagination-active-bg;
$pager-active-color:                   $pagination-active-color;

$pager-disabled-color:                 $pagination-disabled-color;


// Breadcrumbs

$breadcrumb-padding-vertical:   .75rem;
$breadcrumb-padding-horizontal: 1rem;

$breadcrumb-bg:                 $gray-lighter;
$breadcrumb-divider-color:      $gray-light;
$breadcrumb-active-color:       $gray-light;
$breadcrumb-divider:            "/";
