.view-block-card {
  @include extend-container();
  @include media-breakpoint-up(lg) {
    max-width: 720px;
  }
  @include media-breakpoint-up(xl) {
    max-width: 1082px;
  }
}

.view-block-card > .view-content {
  @include media-breakpoint-up(md) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.view-block-card .views-row {
  position: relative;
  width: 33.6rem;
  margin-bottom: 4rem;
  background-color: $gray-lightest;
  @include media-breakpoint-down(sm) {
    margin: 0 auto 4rem auto;
  }
}

.card-teaser-content-wrapper {
  position: relative;
  padding: 1.5rem;
}

.card-teaser-category {
  position: absolute;
  top: -2rem;
  left: 0;
  height: 3.4rem;
  display: flex;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  font-weight: 600;
  font-size: 1.6rem;
  border-top-right-radius: 2rem;
  border-bottom-right-radius: 2rem;
  background-color: $brand-orange;
  color: #fff;
  text-transform: uppercase;
}

.card-teaser-title {
  margin-top: 1.5rem;
  font-size: 2.4rem;
  line-height: 1;
}

.card-teaser-title a {
  font-size: 2.4rem;
  color: $brand-text-gray;
}

.card-teaser-date {
  margin-bottom: 0;
  font-size: 1.8rem;
  text-transform: uppercase;
  color: $brand-dark-blue-2;
}

.view-block-card .field-type-image img {
  height: auto;
  width: 100%;
}

#block-views-featured-workshops-block h2,
#block-views-featured-events-block h2,
#block-views-featured-posts-block h2,
.view-classes-by-instructor h2,
.view-blogs-by-instructor h2 {
  @include extend-container();
  margin-top: 7rem;
  margin-bottom: 4rem;
  font-size: 3.2rem;
  font-weight: 300;
  @include media-breakpoint-up(lg) {
    max-width: 720px;
  }
  @include media-breakpoint-up(xl) {
    max-width: 1082px;
  }
}
