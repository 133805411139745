
/**
 * @file
 * Edit profile page.
 */

body.page-user-edit.form-only-page .tabs {
  width: 100%;
}
#user-profile-form {
  .date-no-float {
    width: 100%;
  }
  fieldset.date-combo .container-inline-date > .form-item {
    margin-right: 0;
  }
  .container-inline-date .date-padding {
    padding: 0;
  }
  .form-type-date-select .form-item {
    width: 140px;
    margin: 0 20px 0 0;
  }
  .form-type-date-select .form-item:last-child {
    margin-right: 0;
  }

}
