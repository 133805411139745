
/**
 * @file
 * Webform node styling.
 */

body.node-type-webform {
  #page-title {
    text-align: center;
    margin-top: 0.5em;
  }
}

.node-webform .field-name-body {
  margin-top: 1em;
}

.lighthousewriters-utilities-webform-require-login {
  background: #ed5;
  text-align: center;
  padding: 15px 20px;
  margin-bottom: 4em;
}
