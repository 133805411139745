.page-search-node .region-content {
  @include extend-container();
  display: flex;
  flex-direction: column;
}

//Reorganizing layout of page
.page-search-node {
  .search-form{
    order: 2;
  }
  .search-other-areas {
    order: 0;
  }
  h2 {
    order: 1;
  }
  .search-results {
    order: 3;
  }
  .text-center {
    order: 4;
  }
}

//Styling spacing on search buttons
.page-search-node  .search-other-areas {
  margin: 0 0 3rem 0;
  a {
    margin: .5rem 0;
  }
}

.block-search {
  @include media-breakpoint-up(md) {
    margin-top: 0;
  }
  @include media-breakpoint-up(lg) {
    margin-top: 1.7rem;
  }
}

.block-search .form-submit {
  display: inline-block;
  padding: 0;
  width: 36px;
  height: 36px;
  border: 2px solid $gray;
  border-radius: 50%;
  transition: all .3s ease-in-out;
  text-indent: -99999px;
  overflow: hidden;
  background: url(../images/search-gray.png) no-repeat 50% 48%;
}

.block-search .form-type-textfield {
  width: 0;
  margin-top: 0;
  overflow: hidden;
  transition: all .3s ease-in-out;
  @include media-breakpoint-up(md) {
    width: 24rem
  }
  @include media-breakpoint-up(lg) {
    width: 0;
  }
}

.block-search .form-type-textfield .form-text {
  width: 100%;
}

.search-active .block-search .form-type-textfield {
  @include media-breakpoint-up(md) {
    width: 24rem;
  }
  @include media-breakpoint-up(lg) {
    width: 32rem;
  }
  @include media-breakpoint-up(xl) {
    width: 36rem;
  }
}

.search-active .block-search .form-text {
  width: 100%;
}

.page-search #block-lighthousewriters-content-lighthouse-jump-nav-menu {
  display: none;
}
.search-results {
  margin: 1em 0 0 0;
  padding: 0;
  li {
    padding: 0.7em 1em;
    border: 1px solid #DDD;
  }
  p {
    margin-bottom: 0.5em;
  }
}
.search-form .container-inline .form-item {
  margin-top: 0;
}
