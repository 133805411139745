.item-list .pager {
  text-transform: uppercase;
  @include media-breakpoint-down(md) {
    display: block;
    width: 100%;
    min-width: 28rem;
    max-width: 37.7rem;
    margin: 0 auto 4rem auto;
  }
  @include media-breakpoint-up(lg) {
    float: right;
    margin-bottom: 4rem;
  }
}

.item-list .pager li {
  margin: 0 0.2rem;
  padding: 0;
}

.pager .pager-item > a,
.pager .active > span {
  height: 4.4rem;
  width: 4.4rem;
  background-color: #fff;
  border: 2px solid $brand-blue;
  border-radius: 4rem;
}

.pager .active > span {
  background-color: $brand-blue;
  color: #fff;
}

.item-list .pager .pager-last {
  margin-right: 0.4rem;
}

.pager .pager-first > a,
.pager .pager-previous > a,
.pager .pager-next > a,
.pager .pager-last > a {
  width: 100%;
  border: 0;
  @include media-breakpoint-up(lg) {
    width: 14rem;
    height: 4.4rem;
    background-color: #fff;
    border: 2px solid $brand-blue;
    border-radius: 4rem;
  }
}
