// Begin hero styles.
.node-type-homepage .field-name-field-hero {
  position: relative;
}

.node-type-homepage .lighthouse-hero-bg-image {
  height: 43rem;
  // Overwriting inline styles with !important.
  background-size: cover !important;
  background-position-x: 30% !important;
  @include media-breakpoint-up(lg) {
    background-position-x: 0;
  }
}

.group-hero-content-wrapper {
  position: absolute;
  top: 6rem;
  width: 100%;
  padding-left: 2rem;
  padding-right: 2rem;
  @include media-breakpoint-up(md) {
    top: 10rem;
  }
  @include media-breakpoint-up(lg) {
    @include extend-container();
    width: 80rem;
    left: 50%;
    margin-left: -46rem;
    text-align: left;
  }
  @include media-breakpoint-up(xl) {
    width: 100rem;
    left: 50%;
    margin-left: -63rem;
  }
}

.group-hero-content-wrapper h1 {
  font-size: 5.5rem;
  line-height: 1;
  @include media-breakpoint-up(md) {
    margin-bottom: 1rem;
  }
}

.group-hero-content-wrapper .field-name-field-additional-text {
  font-size: 3rem;
  line-height: 1.25;
  @include media-breakpoint-up(md) {
    max-width: 60rem;
  }
}

.group-hero-content-wrapper .field-name-field-link {
  margin-top: 2.5rem;
}

.group-hero-content-wrapper .field-name-field-link a {
  @extend .btn;
  @extend .btn-orange;
  padding-left: 4rem;
  padding-right: 4rem;
}
// End hero styles.

// Begin callout 1 styles.
.node-type-homepage .group-call-out {
  padding-top: 2.6rem;
  padding-bottom: 2.6rem;
  text-align: center;
  background-color: $brand-dark-blue;
  @include media-breakpoint-up(md) {
    text-align: left;
  }
}

.node-type-homepage .group-call-out-inner {
  @include media-breakpoint-up(md) {
    @include extend-container();
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.node-type-homepage .group-call-out .field-name-field-title {
  margin: 0 4rem 2rem 4rem;
  text-transform: uppercase;
  font-size: 2.4rem;
  font-family: $font-family-halis;
  font-weight: 600;
  line-height: 1.125;
  color: #fff;
  @include media-breakpoint-up(md) {
    margin-bottom: 0;
  }
}

.node-type-homepage .group-call-out .field-name-field-link a {
  @extend .btn;
  @extend .btn-ghost-white;
}
// End callout 1 styles.

// Begin featured classes.
.node-type-homepage .view-featured-workshops,
.node-type-homepage .view-featured-events,
.node-type-homepage .view-featured-posts {
  margin-bottom: 6rem;
}

.node-type-homepage .field-name-field-image img {
  width: 100%;
  height: auto;
}

.view-featured-workshops .view-footer,
.view-featured-events .view-footer,
.view-featured-posts .view-footer,
.view-classes-by-instructor .view-footer {
  margin-top: 2rem;
  text-align: center;
}

.view-featured-workshops .view-footer a,
.view-featured-events .view-footer a,
.view-featured-posts .view-footer a {
  @extend .btn;
  @extend .btn-ghost-blue;
}
// End featured classes.


// Begin image bar styles.
.node-type-homepage .group-image-bar {
  margin-bottom: 0;
}

.node-type-homepage .group-image-bar .group-image-bar-content-wrapper {
  padding: 4rem;
  text-align: center;
  font-family: $font-family-halis;
  font-size: 2rem;
  font-weight: 300;
  line-height: 1.25;
  color: #fff;
  @include media-breakpoint-only(md) {
    width: 720px;
    margin: 0 auto;
    font-size: 2.2rem;
  }
  @include media-breakpoint-up(lg) {
    width: 50%;
    text-align: left;
  }
  @include media-breakpoint-up(xl) {
    width: 100%;
    min-width: 54rem;
    max-width: 64rem;
    padding-left: 8rem;
    font-size: 2.4rem;
    line-height: 1.375;
  }
}

.node-type-homepage .group-image-bar h3 {
  color: #fff;
  @include media-breakpoint-up(xl) {
    font-size: 4rem;
    margin-bottom: 3rem;
  }
}

.node-type-homepage .group-image-bar .field-name-field-link a {
  @extend .btn;
  @extend .btn-white;
  margin-top: 2rem;
  @include media-breakpoint-up(xl) {
    margin-top: 4rem;
  }
}
// End image bar styles.

// Begin callout 2 styles.
.node-type-homepage .field-name-field-call-out {
  padding-top: 4rem;
  padding-bottom: 4rem;
  background: url(../images/home-callout-bg.jpg) no-repeat;
  background-size: cover;
  color: #fff;
  @include media-breakpoint-up(md) {
    padding-top: 12rem;
    padding-bottom: 12rem;
  }
}

.node-type-homepage .field-name-field-call-out > .field-items {
  @include extend-container();
  text-align: center;
}

.node-type-homepage .field-name-field-call-out h3 {
  margin-bottom: 2rem;
  font-size: 4rem;
  color: #fff;
}

.node-type-homepage .field-name-field-call-out .field-name-field-long-text {
  margin: 0 auto 3rem auto;
  font-size: 3rem;
  line-height: 1.125;
  max-width: 80rem;
}

.node-type-homepage .field-name-field-call-out .field-type-link-field a {
  @extend .btn;
  @extend .btn-white;
}
// End callout 2 styles.

// Homepage slideshow.
.home-slideshow {
  position: relative;
  .slick-list {
    overflow: hidden;
  }
}
#main .home-slideshow .home-slide-wrap {
  width: 100%;
  height: auto;
  position: relative;
  display: flex;
  align-items: center;
  height: 43rem;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
}
@media (max-width: 979px) {
  #main .home-slideshow .home-slide-wrap {
    height: 34rem;
  }
}
@media (max-width: 767px) {
  #main .home-slideshow .home-slide-wrap {
    height: 30rem;
  }
}
.home-slideshow .field-name-field-link,
.home-slideshow .field-name-field-title,
.home-slideshow .field-name-field-long-text {
  position: relative;
  z-index: 10;
}
.home-slideshow .field-name-field-title {
  font-size: 3rem;
  font-weight: 700;
  font-family: "Halis R", Helvetica, Arial, sans-serif;
  line-height: 1;
  text-transform: uppercase;
  margin: 0.67rem 0;
}
@media (min-width: 979px) {
  .home-slideshow .field-name-field-title {
    font-size: 5.5rem;
  }
}
.home-slideshow .field-name-field-long-text {
  font-size: 3rem;
  line-height: 1.25;
}
.home-slideshow .field-name-field-long-text p {
  margin: 0;
}
.home-slideshow .field-name-field-link {
  margin-top: 1.5rem;
}
@media (min-width: 767px) {
  .home-slideshow .field-name-field-link {
    margin-top: 2.5rem;
  }
}
.home-slideshow .field-name-field-link a {
  background-color: #f26c23;
  color: #fff;
  font-size: 2rem;
  border-radius: 3rem;
  font-family: "Halis R", Helvetica, Arial, sans-serif;
  line-height: 2.2;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  transition: all 0.3s ease-in-out;
  display: inline-block;
  padding: 0.6rem 4.5rem;
}
.home-slideshow .field-name-field-link a :hover {
  text-decoration: none;
}
@media (max-width: 540px) {
  .home-slideshow .field-name-field-link a {
    padding: 0.4rem 3rem;
    font-size: 1.5rem;
    text-align: center;
  }  
}
.home-slideshow .field-name-field-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.home-slideshow .field-name-field-image img {
  position: absolute; 
  top: 0; 
  left: 0; 
  right: 0; 
  bottom: 0; 
  margin: auto; 
  min-width: 50%;
  min-height: 50%;
  overflow-x: hidden;
}
.home-slide {
  width: 100%;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}
.slideshow-align-left .home-slide {
  text-align: left;
}
.slideshow-align-right .home-slide {
  text-align: right;
}
.slideshow-align-center .home-slide {
  text-align: center;
}
@media (min-width: 544px) {
  .home-slide {
    max-width: 530px;
    padding-left: 0;
    padding-right: 0;
  }
}
@media (min-width: 768px) {
  .home-slide {
    max-width: 680px;
  }
}
@media (min-width: 992px) {
  .home-slide {
    max-width: 920px;
  }
}
@media (min-width: 1360px) {
  .home-slide {
    max-width: 1245px;
  }
}
.field-group-div.home-slide * {
  color: #FFF;
}
#main .slick-dotted.slick-slider {
  margin-bottom: 0;
}
#main .slick-dots {
  position: absolute;
  bottom: 20px;
  line-height: 1;
  li {
    button {
      background: rgba(0, 0, 0, 0.15);
      border-radius: 10px;
    }
    button:before {
      content: '';
    }
  }
  li.slick-active button {
    background: rgba(0, 0, 0, 0.6);
  }
}
// Homepage slideshow end.