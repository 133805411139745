// Begin main content styles.
.node-type-lit-fest-landing-page .group-main-content {
  @include extend-container();
  margin-bottom: 9rem;
  @include media-breakpoint-up(lg) {
    max-width: 720px;
  }
}

.node-type-lit-fest-landing-page .group-main-content .field-name-field-file a {
  @extend .btn;
  @extend .btn-primary;
  display: block;
  width: 80%;
  margin: 0 auto 2rem auto;
  @include media-breakpoint-up(md) {
    float: left;
    width: auto;
    margin-right: 2rem;
    margin-bottom: 0;
  }
}

.node-type-lit-fest-landing-page .group-main-content .field-name-field-link a {
  @extend .btn;
  @extend .btn-ghost-blue;
  display: block;
  width: 80%;
  margin: 0 auto 2rem auto;
  @include media-breakpoint-up(md) {
    float: left;
    width: auto;
    margin-right: 2rem;
    margin-bottom: 0;
  }
}
// End main content styles.

// Begin sponsored content styles.
.group-sponsored-content {
  @include extend-container();
}

.group-sponsored-content h3 {
  text-align: center;
  margin-bottom: 4rem;
  font-weight: 300;
}

.group-sponsored-content .field-name-field-link-images > .field-items {
  @include media-breakpoint-up(xs) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}

.group-sponsored-content .field-name-field-link-images > .field-items > .field-item {
  margin: 0 2rem 2rem 2rem;
}
// End sponsored content styles.

// Begin action blocks styles.
.field-name-field-action-blocks {
  margin-top: 8rem;
  margin-bottom: 8rem;
}

.field-name-field-action-blocks > .field-items {
  @include media-breakpoint-up(sm) {
    display: flex;
    flex-wrap: wrap;
  }
  @include media-breakpoint-up(xl) {
    max-width: 1800px;
    margin: 0 auto;
  }
}

.field-name-field-action-blocks > .field-items > .field-item {
  position: relative;
  width: 100%;
  max-width: 320px;
  margin: 0 auto 2rem auto;
  @include media-breakpoint-up(sm) {
    width: 50%;
    max-width: 100%;
    margin-bottom: 0;
  }
  @include media-breakpoint-up(lg) {
    width: 25%;
  }
}

.field-name-field-action-blocks img {
  width: 100%;
  height: auto;
}

.field-name-field-action-blocks .field-name-field-image {
  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(#fff, 0.5);
  }
}

.group-action-blocks-wrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 3rem;
}

.group-action-blocks-wrapper h2 {
  font-size: 2.4rem;
  margin-bottom: 1.5rem;
  line-height: 1.25;
  @include media-breakpoint-only(md) {
    font-size: 3.4rem;
  }
  @include media-breakpoint-up(xl) {
    font-size: 3.4rem;
  }
}

.group-action-blocks-wrapper .field-name-field-link a {
  @extend .btn;
  @extend .btn-primary;
  line-height: 1.5;
}
// Begin action blocks styles.

// Begin staggered content styles.
.node-type-lit-fest-landing-page .group-staggered-content-wrapper {
  @include media-breakpoint-up(md) {
    width: 720px;
  }
  @include media-breakpoint-up(lg) {
    width: 900px;
  }
}

.node-type-lit-fest-landing-page .group-staggered-content-wrapper h2 {
  margin-bottom: 3rem;
  text-align: center;
  font-size: 3rem;
  font-weight: 300;
  @include media-breakpoint-up(md) {
    text-align: left;
  }
}

// End staggered content styles.

// Begin event teaser styles.
.field-name-field-event-teaser > .field-items {
  margin-bottom: 10rem;
  background-color: $brand-blue;
  @include media-breakpoint-up(lg) {
    display: flex;
  }
}

.field-name-field-event-teaser > .field-items > .field-item {
  padding: 10rem;
  text-align: center;
  font-family: $font-family-halis;
  font-size: 2rem;
  font-weight: 300;
  line-height: 1.25;
  color: #fff;
  @include media-breakpoint-only(md) {
    font-size: 2.2rem;
  }
  @include media-breakpoint-up(lg) {
    width: 50%;
    text-align: left;
  }
  @include media-breakpoint-up(xl) {
    font-size: 2.4rem;
    line-height: 1.375;
  }
}

.field-name-field-event-teaser > .field-items > .field-item:first-child {
  background: url(../images/event-teaser-bg.jpg) no-repeat;
  background-size: cover;
}

.group-event-teaser-wrapper {
  @include media-breakpoint-up(xl) {
    width: 90%;
    padding-left: 10%;
  }
}

.field-name-field-event-teaser h2 {
  font-size: 3rem;
  line-height: 1.25;
}

.field-name-field-event-teaser .field-name-field-date {
  margin-bottom: 2rem;
  font-size: 2rem;
  font-weight: 400;
}
// End event teaser styles.


.group-festival-passes-wrapper,
.group-business-passes-wrapper {
  @include extend-container();
  text-align: center;
}

.group-festival-passes-wrapper h2,
.group-business-passes-wrapper h2 {
  margin-bottom: 8rem;
  font-size: 3.6rem;
  color: $gray-dark;
}

.group-festival-passes-wrapper h3,
.group-business-passes-wrapper h3 {
  color: $gray-dark;
}

.group-festival-passes-wrapper .views-field-commerce-price p,
.group-business-passes-wrapper .views-field-commerce-price p {
  margin-bottom: 1.5rem;
  font-size: 3rem;
  font-weight: bold;
  color: $gray-dark;
}

.group-festival-passes-wrapper .view-passes .view-content,
.group-business-passes-wrapper .view-passes .view-content {
  @include media-breakpoint-up(lg) {
    display: flex;
    flex-wrap: wrap;
  }
}

.group-festival-passes-wrapper .view-passes .views-row,
.group-business-passes-wrapper .view-passes .views-row {
  margin-bottom: 8rem;
  @include media-breakpoint-up(lg) {
    width: 50%;
    padding: 0 4rem;
  }
  @include media-breakpoint-up(xl) {
    padding: 0 10rem;
  }
}

.group-other-offerings-wrapper {
  @include extend-container();
  margin-bottom: 8rem;
  @include media-breakpoint-up(xl) {
    max-width: 880px;
  }
}

.group-other-offerings-wrapper h2 {
  text-align: center;
  font-size: 3.6rem;
  color: $gray-dark;
  @include media-breakpoint-up(lg) {
    margin-bottom: 8rem;
  }
}

.group-other-offerings-wrapper .view-passes {
  @include media-breakpoint-up(sm) {
    max-width: 50rem;
    margin: 0 auto;
  }
  @include media-breakpoint-up(lg) {
    max-width: 100%;
  }
}

.group-other-offerings-wrapper .views-row {
  clear: both;
  display: inline-block;
  width: 100%;
  padding: 1rem 0;
  border-bottom: 1px solid $gray-light;
  @include media-breakpoint-up(lg) {
    display: flex;
  }
}

.group-other-offerings-wrapper .views-row > div {
  @include media-breakpoint-up(lg) {
    width: 18%;
  }
}

.group-other-offerings-wrapper .views-row .views-field-title {
  font-weight: bold;
  @include media-breakpoint-up(lg) {
    width: 50%;
    font-weight: normal;
  }
}

.group-other-offerings-wrapper .member-label {
  @include media-breakpoint-up(lg) {
    // .sr-only styles inside media query.
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }
}

.group-other-offerings-wrapper .views-row-first .views-field-title,
.group-other-offerings-wrapper .views-row-first .views-field-path {
  padding-top: 2.8rem;
}

.group-other-offerings-wrapper .views-field-path,
.group-other-offerings-wrapper .views-row-first .views-field-path {
  @include media-breakpoint-down(md) {
    padding-top: 3rem;
  }
}

.group-other-offerings-wrapper .views-row-first .member-label {
    @extend %sr-only-override;
}

.group-other-offerings-wrapper .member-wrapper {
  float: left;
  margin-right: 3rem;
}

.view-passes .views-field-path .btn {
  @extend .btn-ghost-blue;
  margin-top: 3rem;
}

// Hide the "Festival Passes" region for certain nodes.
.page-node-2607 {
  .group-festival-passes-wrapper,
  .group-business-passes-wrapper {
    display: none!important;
  }
  .field-name-field-event-teaser>.field-items {
    margin-bottom: 0;
  }
}
