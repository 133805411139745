// Begin exposed filter styles.
.view-workshops .views-exposed-form {
  margin-bottom: 6rem;
  @include media-breakpoint-up(lg) {
    margin-bottom: 10rem;
  }
}

.view-workshops .views-exposed-form .form-submit {
  float: right;
  margin-top: 1.6rem;
}

.view-workshops .views-exposed-form .views-exposed-widget {
  padding: 0;
}

.view-workshops .views-exposed-form .select-style {
  background: #fff url(../images/select-control-simple-gray.png) no-repeat 90% 50%;
  padding: 1rem 0;
  margin: 0 0 0.1rem 0;
  width: 100%;
  border-radius: 0;
  overflow: hidden;
  background-color: #ddd;
  border: 0;
}

.view-workshops .views-exposed-form .select-style select {
  text-transform: uppercase;
  width: 100%;
  padding-right: 55px;
}

.view-workshops .views-exposed-form .form-type-textfield {
  width: 100%;
  margin-bottom: 0.1rem;
  @include media-breakpoint-up(lg) {
    position: relative;
    width: 240px;
    border-right: 1px solid #fff;
  }
  @media (min-width: 1360px) {
    width: 257px;
    &.form-item-fuzzy-search {
      width: 258px;
    }
  }
  &.form-item-fuzzy-search {
    border-right: 0;
  }
}

.view-workshops .views-exposed-form .form-type-textfield input {
  text-transform: uppercase;
}

.view-workshops .views-exposed-form .form-item-keywords input,
.view-workshops .views-exposed-form .form-item-fuzzy-search input {
  padding-right: 4rem;
  background: url(../images/search-small.png) no-repeat 97% 50%;
  color: $gray;
}

.view-workshops .views-exposed-form .form-item-instructor input {
  padding-right: 4rem;
  background-position: 97% 50%;
}

.view-workshops .views-exposed-form input.form-text {
  height: 6.4rem;
  border-radius: 0;
  background-color: #ddd;
  border: 0;
}

.view-workshops .views-exposed-form .form-type-select {
  float: left;
  width: 50%;
  border-right: 1px solid #fff;
  @include media-breakpoint-up(lg) {
    width: 240px;
  }
  @media (min-width: 1360px) {
    width: 257px;
    &.form-item-workshop-type {
      width: 258px;
    }
  }
}

.view-workshops .views-exposed-form .help-block {
  position: relative;
  top: -0.8rem;
  font-size: 1.3rem;
  @include media-breakpoint-up(lg) {
    position: absolute;
    top: -2rem;
    left: 0.5rem;
    width: 30rem;
    line-height: 1;
    font-family: $font-family-sans-serif;
  }
}

.view-workshops .views-ef-fieldset-container {
  clear: both;
}

.view-workshops .views-ef-fieldset-container-container-0 .fieldset-wrapper {
  @include media-breakpoint-down(md) {
    display: none;
  }
  @include media-breakpoint-up(lg) {
    display: flex !important;
  }
}

.view-workshops .views-ef-fieldset-container-container-0 .select-style {
  background: #fff url(../images/select-control-simple-white.png) no-repeat 90% 50%;
  padding: 0;
  margin: 0 0 0.1rem 0;
  width: 100%;
  border-radius: 0;
  overflow: hidden;
  background-color: $gray;
  border: 0;
  color: #fff;
}

.view-workshops .views-ef-fieldset-container-container-0 .select-style select {
  color: #fff;
  padding-right: 35px;
  width: 100%;
}

.view-workshops .views-ef-fieldset-container-container-0 .select-style [selected="selected"] {
  color: #fff;
}

.view-workshops .views-ef-fieldset-container-container-0 .select-style option {
  color: $gray;
}

.view-workshops .views-ef-fieldset-container-container-0 .panel-body {
  @include media-breakpoint-up(lg) {
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: $gray;
  }
}

.view-workshops .views-ef-fieldset-container-container-0 .form-type-select {
  @include media-breakpoint-up(lg) {
    float: none;
    width: 16%;
    border-color: $gray-lightest;
    &:last-child {
      border: 0;
    }
    @media (min-width: 1360px) {
      width: 165px;
    }
  }
}

.view-workshops .views-ef-fieldset-container-container-0 .panel-heading {
  @include media-breakpoint-up(lg) {
    display: none;
  }
}

.view-workshops .views-ef-fieldset-container-container-0 .panel-title {
  text-align: right;
  font-family: $font-family-sans-serif;
  font-weight: normal;
  color: $brand-dark-blue-2;
  cursor: pointer;
  &:after {
    @extend %icon;
    content: '\e904';
    margin-left: 1.5rem;
  }
}

// End exposed filter styles.

.view-workshops {
  @include extend-container();
  @include media-breakpoint-up(lg) {
    max-width: 98rem;
  }
  @include media-breakpoint-up(xl) {
    max-width: 105rem;
  }
}

.view-workshops .view-content {
  @include media-breakpoint-only(md) {
    display: flex;
    flex-wrap: wrap;
  }
}

.view-workshops .view-content .views-row {
  width: 100%;
  min-width: 28rem;
  max-width: 37.7rem;
  margin: 0 auto 4rem auto;
  @include media-breakpoint-only(md) {
    width: 33rem;
    background-color: $gray;
  }
  @include media-breakpoint-up(lg) {
    max-width: 100%;
  }
}

.workshop-card-top {
  position: relative;
  border: 1px solid $gray-light;
  @include media-breakpoint-up(lg) {
    display: flex;
    border-bottom: 0;
  }
}

.workshop-card-top-image {
  @include media-breakpoint-up(lg) {
    width: 40%;
  }
}

.workshop-card-top-image img {
  width: 100%;
  height: auto;
}

.workshop-card-top-text {
  padding: 2rem;
  @include media-breakpoint-only(md) {
    background-color: #fff;
  }
  @include media-breakpoint-up(lg) {
    width: 60%;
  }
}

.workshop-card-top-text .views-field-field-ref-genre {
  position: absolute;
  top: 0;
  left: -.1rem;
  height: 3.4rem;
  display: flex;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  font-weight: 600;
  font-size: 1.6rem;
  border-top-right-radius: 2rem;
  border-bottom-right-radius: 2rem;
  background-color: $brand-orange;
  color: #fff;
  text-transform: uppercase;
  @include media-breakpoint-up(lg) {
    top: auto;
    bottom: -1.7rem;
  }
}

.workshop-card-top-text .views-field-field-session-description {
  margin-bottom: 1rem;
  font-size: 1.6rem;
}


.workshop-card-top-text .workshop-card-tags {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0;
  font-size: 1.6rem;
  font-weight: 600;
}

.workshop-card-top-text .field-name-field-tags {
  float: left;
}

.workshop-card-top-text .field-name-field-tags .field-item,
.workshop-card-top-text .views-field-new {
  color: $gray-dark;
  font-size: 1.6rem;
  font-weight: 600;
  color: $gray-dark;
  text-transform: uppercase;
  background-color: $gray-light;
  padding: 0 1rem;
  float: left;
  margin-left: 1px;
}



.workshop-card-top-text .btn {
  width: 100%;
  margin-bottom: 1rem;
  padding-top: 0;
  padding-bottom: 0;
}

.workshop-card-top-text .views-field-add-to-cart-form {
  margin-bottom: 2rem;
}

.workshop-card-top-text .views-field-nothing p {
  font-size: 1.4rem;
  text-align: center;
}

.workshop-card-top-text .views-field-nothing-1 {
  margin-right: 1rem;
}


.workshop-card-top-text .views-field-nothing-1 a {
  @extend .btn-ghost-blue;
}

.workshop-card-price-wrapper .field-content {
  display: flex;
}

.workshop-card-price {
  &:first-child {
    margin-right: 2rem;
  }
}

.workshop-card-top-text .views-field-commerce-price {
  clear: both;
}

.workshop-card-top-text .action-wrappers {
  display: flex;
}

.workshop-card-top-text .action-wrappers a {
  @include media-breakpoint-down(md) {
    width: 13.5rem;
    padding: 0;
    font-size: 1.4rem;
  }
}

.workshop-card-bottom {
  padding: 2rem;
  text-align: center;
  background-color: $gray;
  color: #fff;
  @include media-breakpoint-up(lg) {
    display: flex;
  }
}

.workshop-card-bottom .views-field {
  margin-bottom: 1rem;
  padding: 1rem 0;
  border-bottom: 1px solid #7a7a7a;
  &:first-child {
    padding-top: 0;
  }
  &:last-child {
    padding-bottom: 0;
    border: 0;
  }
  @include media-breakpoint-up(lg) {
    width: 25%;
    border-bottom: 0;
    border-right: 1px solid #7a7a7a;
  }
}

.workshop-card-bottom .views-label {
  display: block;
  line-height: 1;
  font-weight: bold;
  text-transform: uppercase;
}

@include media-breakpoint-down(md) {
  .views-ef-fieldset-container .panel-heading {
    margin-bottom: 0;
  }
  .view-workshops .views-ef-fieldset-container-container-0 .panel-title {
    padding: 0 10px;
    box-sizing: border-box;
    line-height: 1;
    margin-bottom: 15px;
  }
  .view-workshops .views-ef-fieldset-container-container-0 .panel-title:after {
    vertical-align: top;
  }
}
