.view-staff,
.view-instructors {
  @include extend-container();
  .view-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .views-row {
    width: 48%;
    text-align: center;
    margin: 2rem 0;
    @include media-breakpoint-up(md) {
      width: 30%;
    }
    @include media-breakpoint-up(lg) {
      width: 19%;
    }
  }
  img {
    border-radius:  100%;
  }
  .views-field-field-position,
  .views-field-field-profile-expertise {
    font-size: 1.4rem;
    line-height: 1;
  }
}

