
/**
 * @file
 * Styling for primary tabs.
 */

.tabs {
  @include extend-container();
  margin: 2em auto;
  ul {
    padding: 0 1rem;
    li {
      display: inline-block;
      border: 1px solid #DDD;
      margin-bottom: -1px;
      background-color: #EEE;
      border-radius: 1px 1px 0 0;
      a {
        padding: 0.5rem 1.5rem;
        font-size: 0.9em;
        display: block;
      }
      a:hover {
        text-decoration: none;
      }
    }
    li.active {
      background-color: #FFF;
      border-bottom-color: #FFF;
    }
    @include media-breakpoint-down(md) {
      li {
        clear: both;
        display: block;
      }
    }
  }
}
