
/**
 * @file
 * Roster page styling.
 */

body.page-roster #page-title {
  margin-top: 0.5em;
  margin-bottom: 0.25em;
}
.view-id-roster {
  .view-header {
    float: left;
    line-height: 42px;
  }
  .view-filters {
    float: right;
    .views-exposed-widgets {
      margin: 0;
    }
    .views-widget-per-page {
      padding: 0 1em 0 0;
      width: 220px;
    }
    .views-submit-button {
      padding: 0;
    }
    .views-exposed-widget .form-submit {
      margin-top: 0;
      font-size: 0.8em;
      line-height: 30px;
      padding-left: 1em;
      padding-right: 1em;
    }
  }
  .view-empty,
  .view-content {
    float: left;
    width: 100%;
    padding: 2em 0;
  }
}

