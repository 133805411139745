
/**
 * @file
 * Styles for form only pages.
 */

.form-only-page {
  .tabs {
    width: 480px;
    margin-top: 0;
    margin-bottom: 0;
  }
  #page-title {
    text-align: center;
    margin-top: 1em;
    margin-bottom: 0.5em;
  }
  .region-content {
    width: 100%;
    max-width: 460px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3em;
    .form-submit {
      width: 100%;
    }
  }
}
