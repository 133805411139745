.lighthouse-jump-nav-container {
  @include extend-container();
  margin-top: 6rem;
  margin-bottom: 5rem;
  @include media-breakpoint-only(lg) {
    max-width: 100%;
  }
}

.lighthouse-jump-nav {
  padding: 0;
  list-style-type: none;
  @include media-breakpoint-up(lg) {
    display: flex;
  }
}

.lighthouse-jump-nav li {
  margin-bottom: 1px;
  @include media-breakpoint-up(lg) {
    flex: 1 1 0;
    margin-bottom: 0;
    margin-right: 1px;
    text-align: center;
  }
}

.lighthouse-jump-nav li a {
  display: inline-block;
  width: 100%;
  padding: 1rem;
  text-transform: uppercase;
  font-weight: bold;
  font-family: $font-family-halis;
  background-color: $brand-blue;
  color: #fff;
  @include media-breakpoint-up(lg) {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 9rem;
  }
  &:hover {
    background-color: $brand-dark-blue-2;
    text-decoration: none;
  }
}
