@font-face {
  font-family: 'IcoMoon-Free';
  src:  url('../fonts/icons/icomoon/icomoon.eot?28e0lg');
  src:  url('../fonts/icons/icomoon/icomoon.eot?28e0lg#iefix') format('embedded-opentype'),
    url('../fonts/icons/icomoon/icomoon.ttf?28e0lg') format('truetype'),
    url('../fonts/icons/icomoon/icomoon.woff?28e0lg') format('woff'),
    url('../fonts/icons/icomoon/icomoon.svg?28e0lg#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'IcoMoon-Free-New';
  src: url('../fonts/icomoon-free/IcoMoon-Free.ttf') format('truetype');
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    &:before {
        font-family: 'IcoMoon-Free' !important;
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;

        /* Better Font Rendering =========== */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
}

%icon {
     /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'IcoMoon-Free' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Enable Ligatures ================ */
    letter-spacing: 0;
    -webkit-font-feature-settings: "liga";
    -moz-font-feature-settings: "liga=1";
    -moz-font-feature-settings: "liga";
    -ms-font-feature-settings: "liga" 1;
    -o-font-feature-settings: "liga";
    font-feature-settings: "liga";

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

// Use when extend is not availalbe (i.e., in media queries).
@mixin icon() {
   /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'IcoMoon-Free' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon:before,
.icon:after {
    @extend %icon;
}

.icon-hambureger-menu:before {
  content: "\e90d";
}
.icon-check_box_outline_blank:before {
  content: "\e908";
}
.icon-check_box:before {
  content: "\e909";
}
.icon-radio_button_checked:before {
  content: "\e90a";
}
.icon-radio_button_unchecked:before {
  content: "\e90b";
}
.icon-cancel:before {
  content: "\e900";
}
.icon-quotes-left:before {
  content: "\e910";
}
.icon-ldquo:before {
  content: "\e910";
}
.icon-arrow-right:before {
  content: "\e90c";
}
.icon-right:before {
  content: "\e90c";
}
.icon-next:before {
  content: "\e90c";
}
.icon-twitter:before {
  content: "\e901";
}
.icon-facebook:before {
  content: "\e902";
}
.icon-envelope-o:before {
  content: "\e90f";
}
.icon-pencil:before {
  content: "\e90e";
}
.icon-angle-up:before {
  content: "\e903";
}
.icon-angle-down:before {
  content: "\e904";
}
.icon-arrow-right2:before {
  content: "\e905";
}
.icon-search:before {
  content: "\e906";
}
.icon-instagram:before {
  content: "\e907";
}

// Mixin for icons
@mixin facebook-icon() {
  &:before {
    @extend %icon;
    content: "\ea90";
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    border: 2px solid $brand-blue;
    border-radius: 50%;
    font-size: 1.7rem;
    transition: all .3s ease-in-out;
  }
  &:hover {
    text-decoration: none;
    &:before {
      border-color: $brand-dark-blue-2;
    }
  }
  span {
    @extend .sr-only;
  }
}

@mixin twitter-icon() {
  &:before {
    @extend %icon;
    content: "\ea96";
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    border: 2px solid $brand-blue;
    border-radius: 50%;
    font-size: 1.7rem;
    transition: all .3s ease-in-out;
  }
  &:hover {
    text-decoration: none;
    &:before {
      border-color: $brand-dark-blue-2;
    }
  }
  span {
    @extend .sr-only;
  }
}

@mixin instagram-icon() {
  &:before {
    @extend %icon;
    content: "\ea92";
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    border: 2px solid $brand-blue;
    border-radius: 50%;
    font-size: 1.7rem;
    transition: all .3s ease-in-out;
  }
  &:hover {
    text-decoration: none;
    &:before {
      border-color: $brand-dark-blue-2;
    }
  }
  span {
    @extend .sr-only;
  }
}

@mixin soundcloud-icon() {
  &:before {
    @extend %icon;
    content: "\eac3";
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    border: 2px solid $brand-blue;
    border-radius: 50%;
    font-size: 1.7rem;
    transition: all .3s ease-in-out;
  }
  &:hover {
    text-decoration: none;
    &:before {
      border-color: $brand-dark-blue-2;
    }
  }
  span {
    @extend .sr-only;
  }
}

@mixin youtube-icon() {
  &:before {
    @extend %icon;
    content: "\ea9d";
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    border: 2px solid $brand-blue;
    border-radius: 50%;
    font-size: 1.7rem;
    transition: all .3s ease-in-out;
  }
  &:hover {
    text-decoration: none;
    &:before {
      border-color: $brand-dark-blue-2;
    }
  }
  span {
    @extend .sr-only;
  }
}

@mixin arrow-right-icon() {
  font-size: 3rem;
  text-transform: uppercase;
  font-family: $font-family-halis;
  font-weight: bold;
  color: $brand-text-gray;
  @include media-breakpoint-up(md) {
    font-size: 4rem;
  }
  &:after {
    @extend %icon;
    content: "\e905";
    display: inline-block;
    position: relative;
    width: 5rem;
    height: 5rem;
    left: 3rem;
    padding: .2rem 0 0 .2rem;
    border: 6px solid $brand-orange;
    border-radius: 50%;
    font-size: 3rem;
    color: $brand-orange;
    transition: all .3s ease-in-out;
  }
  @include media-breakpoint-up(md) {
    &:after {
      font-size: 3.5rem;
      width: 5.5rem;
      height: 5.5rem;
    }
  }
  &:hover {
    text-decoration: none;
    &:after {
      left: 4.5rem;
    }
  }
}

// Mixin calls
.facebook-icon {
  @include facebook-icon();
}

.twitter-icon {
  @include twitter-icon();
}

.instagram-icon {
  @include instagram-icon();
}

.soundcloud-icon {
  @include soundcloud-icon();
}

.youtube-icon {
  @include youtube-icon();
}

.arrow-right-icon {
  @include arrow-right-icon();
}
