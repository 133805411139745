@font-face {
  font-family: 'Calibri';
  src: url('../fonts/text/Calibri.eot?#iefix') format('embedded-opentype'),
    url('../fonts/text/Calibri.woff') format('woff'),
    url('../fonts/text/Calibri.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Calibri';
  src: url('../fonts/text/Calibri-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/text/Calibri-BoldItalic.woff') format('woff'),
    url('../fonts/text/Calibri-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Calibri';
  src: url('../fonts/text/Calibri-Italic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/text/Calibri-Italic.woff') format('woff'),
    url('../fonts/text/Calibri-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Calibri';
  src: url('../fonts/text/Calibri-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/text/Calibri-Bold.woff') format('woff'),
    url('../fonts/text/Calibri-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Halis R';
  src: url('../fonts/text/HalisR-Thin.eot?#iefix') format('embedded-opentype'),
    url('../fonts/text/HalisR-Thin.woff') format('woff'),
    url('../fonts/text/HalisR-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Halis R';
  src: url('../fonts/text/HalisR-Medium.eot?#iefix') format('embedded-opentype'),
    url('../fonts/text/HalisR-Medium.woff') format('woff'),
    url('../fonts/text/HalisR-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Halis R';
  src: url('../fonts/text/HalisR-Light.eot?#iefix') format('embedded-opentype'),
    url('../fonts/text/HalisR-Light.woff') format('woff'),
    url('../fonts/text/HalisR-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Halis R';
  src: url('../fonts/text/HalisR-Regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/text/HalisR-Regular.woff') format('woff'),
    url('../fonts/text/HalisR-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Halis R';
  src: url('../fonts/text/HalisR-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/text/HalisR-Bold.woff') format('woff'),
    url('../fonts/text/HalisR-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
