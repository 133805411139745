#block-webform-client-block-2 {
  @include extend-container-max-width-full();
  margin-top: -4rem;
  margin-bottom: 5rem;
  @include media-breakpoint-up(lg) {
    margin-top: -10rem;
  }
}

form.webform-client-form-2 {
  width: 100%;
}

form.webform-client-form-2 .panel-title {
  display: none;
}

form.webform-client-form-2 .donation-details .panel-title {
  display: block;
  color: #000;
}

#block-webform-client-block-2 .content #webform-stripe-form form > div {
  @include media-breakpoint-up(lg) {
    display: flex;
  }
}

fieldset.donation-block {
  padding: 25rem 12rem 25rem 12rem;
  background: url(../images/donate-bg.jpg) no-repeat;
  background-size: cover;
  @include media-breakpoint-up(lg) {
    width: 50%;
  }
  @include media-breakpoint-down(lg) {
    padding: 15rem 4rem;
  }
}

fieldset.donation-block h3, fieldset.donation-block p {
  color: #fff;
}

fieldset.donation-form {
  padding: 12rem 20rem 10rem 12rem;
  background-color: $gray-lightest;
  @include media-breakpoint-up(lg) {
    width: 50%;
  }
  @include media-breakpoint-down(lg) {
    padding: 6rem 3rem;
  }
}

fieldset.donation-form .form-required {
  display: none;
}

fieldset.donation-form .donation-amount .form-type-radio {
  display: inline-block;
}

.form-item-submitted-donation-form-make-a-donation-donation-details-make-a-donation-target-select label,
.webform-component--donation-form--make-a-donation-donation-details--make-a-donation-anonymous label {
  font-weight: normal;
}

.form-item-submitted-donation-form-make-a-donation-donation-details-make-a-donation-target-select select {
  color: $brand-blue;
}

form.webform-client-form-2 .form-item {
  margin-top: 0;
}

form.webform-client-form-2 .webform-component-boolean {
  margin-bottom: .25rem;
}
