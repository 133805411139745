//
// Layout
// Define your layout requirements here, separated from your design elements.
// Use bootstrap grid or not, up to you.
//

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
      box-sizing: border-box;
}

main {
  width: 100%;
  @include media-breakpoint-up(md) {
    @include make-container;
    padding: 0;
  }
}

// Extend container styles to all h1 titles.
h1.title {
  @include extend-container();
}

.width-50 {
  @include media-breakpoint-up(lg) {
    float: left;
    width: 50%;
  }
}

// Commenting out, but leaving in for reference throughout development.
// .grid-layout {
//   max-width: 1200px;
// }

// .one-hundred-percent {
//   width: 100%;
//   height: 100px;
// }

// .six-columns {
//   height: 100px;
//   @include make-col;
//   @include make-col-span(6);
// }

// .ten-columns {
//   height: 100px;
//   @include make-col;
//   @include make-col-span(10);
// }

// .twelve-columns {
//   height: 100px;
//   @include make-col;
//   @include make-col-span(12);
// }

// .bg-green {
//   background-color: green;
// }
// .bg-yellow {
//   background-color: yellow;
// }
// .bg-orange {
//   background-color: orange;
// }
// .bg-red {
//   background-color: red;
// }
