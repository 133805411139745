body {
  font-size: $font-size-lg;
  color: $brand-text-gray;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// Make sure we don't affect admin menu.
#admin-menu {
  -webkit-font-smoothing: auto;
}

p, ul, ol {
  font-size: $font-size-lg;
  margin-bottom: 4.3rem;
  line-height: 3rem;
}

h1 {
  font-size: 5rem;
  line-height: 6.5rem;
  font-weight: bold;
  font-family: $font-family-halis;
  text-transform: uppercase;
}

h2 {
  font-size: 4rem;
  line-height: 5.2rem;
  font-weight: bold;
  font-family: $font-family-halis;
  text-transform: uppercase;
}

h3 {
  font-size: 3rem;
  line-height: 3.6rem;
  font-weight: bold;
  font-family: $font-family-halis;
  color: $gray;
  text-transform: uppercase;
}

h4 {
  font-size: 2.4rem;
  line-height: 3.1rem;
  font-weight: bold;
  font-family: $font-family-halis;
  text-transform: uppercase;
}

h5 {
  font-size: 2.2rem;
  line-height: 2.8rem;
  font-weight: bold;
  font-family: $font-family-halis;
  color: $gray;
  text-transform: uppercase;
}

h6 {
  font-size: 1.8rem;
  line-height: 2.3rem;
  font-weight: bold;
  font-family: $font-family-halis;
  text-transform: uppercase;
}
