.confirmation-become-member {
  display: inline-block;
  margin-bottom: 3rem;
}

.confirmation-become-member .btn-primary {
  width: 100%;
  @include media-breakpoint-up(sm) {
    float: left;
    width: auto;
  }
}

.confirmation-become-member .link-arrow {
  display: block;
  width: 100%;
  text-align: center;
  @include media-breakpoint-up(sm) {
    float: right;
    width: auto;
    margin-top: 1.4rem;
    text-align: left;
  }
}
