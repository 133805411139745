.node-type-lit-fest-internal-page .group-main-content  {
  @include extend-container();
  margin-bottom: 4rem;
  @include media-breakpoint-up(md) {
    max-width: 720px;
    margin: 0 auto 8rem auto;
  }
}

.node-type-lit-fest-internal-page .group-main-content .field-name-field-link a {
  @extend .link-arrow;
}

.node-type-lit-fest-internal-page .field-name-field-event-teaser > .field-items {
  margin-bottom: 0;
}
