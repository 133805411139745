#block-views-faqs-block {
  @include extend-container();
}

.view-faqs .view-content {
  @include media-breakpoint-up(xl) {
    max-width: 62rem;
    margin: 0 auto;
  }
}

.view-faqs .view-content h3 {
  margin-bottom: 3rem;
}

.view-faqs .view-content .views-row-last {
  margin-bottom: 4.5rem;
}

.view-faqs .views-field-title {
  cursor: pointer;
  width: 100%;
  font-weight: bold;
  margin-bottom: 1.5rem;
  
  :after {
    @extend %icon;
    content: "\e904";
    float: right;
  }
}

.view-faqs .views-field-title.on {
  :after {
    content: "\e903";
  }
}

.view-faqs .views-field-title span {
  width: 100%;
  display: block;
}

.view-faqs .view-content .views-field-body {
  display: none;
}

.view-faqs .view-content .views-row-first:first-child .views-field-body {
  display: block;
}

.view-faqs .view-content .views-row {
  border-top: 1px solid $gray-light;
  padding-top: 1rem;
}

.view-faqs .view-content .views-row-first {
  border-top: none;
  padding-top: 0;
}
