.page-login main {
  @include extend-container-max-width-full();
}

.page-login .region-content {
  @include media-breakpoint-up(lg) {
    display: flex;
  }
}

div#user-login-form {
  padding: 2rem 2rem 6rem 2rem;
  background: url(../images/login-bg.jpg) no-repeat;
  background-size: cover;
  @include media-breakpoint-up(lg) {
    width: 50%;
  }
}

div#user-register-form {
  padding: 2rem 2rem 4rem 2rem;
  background-color: $brand-blue;
  @include media-breakpoint-up(lg) {
    width: 50%;
  }
}

.user-login-block {
  width: 100%;
  @include media-breakpoint-up(lg) {
    float: right;
  }
}

.user-register-form {
  width: 100%;
  @include media-breakpoint-up(lg) {
    float: left;
  }
}

.user-login-block,
.user-register-form {
  max-width: 70rem;
  margin: 0 auto;
  @include media-breakpoint-up(lg) {
    padding: 5rem;
  }
  @include media-breakpoint-up(xl) {
    padding: 10rem;
  }
}

.user-register-form h2 {
  color: #fff;
}

.user-login-block .form-control {
  border-width: 2px;
}

.forgot-password-link {
  float: right;
}

.user-register-form .form-type-checkbox label {
  &:before {
    color: #fff;
  }
}

.user-register-form .help-block {
  display: inline-block;
  margin-top: 1rem;
  margin-left: .5rem;
  line-height: 1.125;
  font-size: 1.4rem;
  color: #fff;
}

.user-register-form .form-submit {
  @extend .btn-ghost-white;
}

.user-register-form label {
  color: #fff;
}

.user-register-form .form-required {
  color: #fff;
}

.fieldset-legend {
  color: #fff;
}

.user-login-block .form-submit,
.user-register-form .form-submit {
  width: 100%;
}

.new-site-text {
  clear: both;
  padding-top: 2rem;
}

.page-user-register .user-register-form{
  background: #009aa5;
  width: 100%;
  max-width: 100%;
}
