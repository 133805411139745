// This starts the styling for the instructor profile.
.page-user.profile-page h1 {
  display: none;
}

.page-user #block-views-profile-hero-block {
  height: 350px;
  padding: 3.5rem 0 0 0;
  background: url(../images/profile-bg.jpg) no-repeat;
  background-size: cover;
}

.page-user #block-views-profile-hero-block .content {
  @include extend-container();
}

.page-user #block-views-profile-hero-block .content .view {
  text-align: center;
}

.page-user #block-views-profile-hero-block .field-name-field-first-name,
.page-user #block-views-profile-hero-block .field-name-field-last-name, {
  display: inline-block;
  color: #fff;
  font-size: 5rem;
  text-transform: uppercase;
  font-weight: bold;
  margin: .75rem 1.5rem 0 0;
  font-family: "Halis R", Helvetica, Arial, sans-serif;
}

.page-user #block-views-profile-hero-block .field-name-field-last-name {
  margin-right: 0px;
}

.page-user #block-views-profile-hero-block .field-name-field-title {
  color: #fff;
  font-weight: 400;
  font-size: 2.5rem;
}

.page-user #block-views-profile-hero-block .views-field-picture img {
  border-radius: 50%;
  width: 17.5rem;
  height: 17.5rem;
}

.page-user .expertise-block {
  @include extend-container;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 6rem;
  @include media-breakpoint-up(xl) {
    flex-direction: row;
  }
}

.page-user .expertise-block .expertise-title {
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  color: #fff;
  width: 100%;
  height: 5rem;
  background: $brand-blue;
  font-weight: 700;
  font-size: 2rem;
  @include media-breakpoint-up(xl) {
    width: 25rem;
    height: 10rem;
  }
}

.page-user .expertise-block .expertise-list {
  width: 100%;
  background: $gray;
  @include media-breakpoint-up(xl) {
    flex: 1;
    height: 10rem;
  }
}

.page-user .expertise-block ul {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  margin-bottom: 0;
  padding: 0;
  @include media-breakpoint-up(xl) {
    flex-direction: row;
    height: 10rem;
  }

}

.page-user .expertise-block ul li {
  list-style: none;
  color: #fff;
  text-align: center;
  display: inline-block;
  width: 100%;
  padding: 0 2rem;
  border-bottom: 1px solid $gray-light;
  display: block;
  &:last-child {
    border-bottom: 0;
  }
  @include media-breakpoint-up(xl) {
    border-bottom: 0;
    border-right: 1px solid $gray-light;
    &:last-child {
      border-right: 0;
    }
  }
}

.page-user .expertise-block ul li:last-child span {
  border-right: none;
}

.page-user .profile-information {
  margin: 10rem auto;
  @include extend-container();
}

.page-user .profile-information .profile-information-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 83rem;
  width: 100%;
  margin: 0 auto;
  @include media-breakpoint-up(lg) {
    flex-direction: row;
    align-items: flex-start;
  }
}

.page-user .profile-information-left {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 30rem;
  text-align: center;
}

.page-user .profile-information-left img {
  margin-bottom: 2rem;
}

.page-user .profile-information-right {
  width: 65%;
  padding-left: 4rem;
}

.page-user .user-picture {
  float: none;
  text-align: center;
}

.page-user .profile-information-left a {
  display: block;
}

.page-user .profile-information-left .flag-flag-instructor {
  margin-top: 1.5rem;
  display: block;
}

.page-user #classes-by-instructor {
  background: url(../images/profile-instructor-bg.jpg) no-repeat;
  background-size: cover;
  padding: 10rem 20rem;

  @include media-breakpoint-down(md) {
    padding: 6rem 0;
  }
}

.page-user .view-classes-by-instructor h2 {
  padding-left: 0;
  font-weight: 500;
  color: #fff;
  @include media-breakpoint-down(md) {
    text-align: center;
  }
}

.page-user .card-teaser-title {
  border-bottom: none;
}

.page-user .view-footer a {
  @extend .btn;
  @extend .btn-ghost-white;
}

.page-user #profile-written-works {
  padding: 10rem 0;
  @include extend-container();
}

.page-user #profile-written-works h2 {
  margin-top: 7rem;
  margin-bottom: 4rem;
  font-size: 3.2rem;
  font-weight: 300;
  padding-left: 0;
  @include media-breakpoint-up(lg) {
    max-width: 720px;
  }
  @include media-breakpoint-up(xl) {
    max-width: 1082px;
  }

  @include media-breakpoint-down(md) {
    text-align: center;
    margin-top: 3rem;
  }
}

.page-user #profile-written-works h4 {
  font-size: 2rem;
  line-height: 1.125;
}

.page-user #profile-written-works ul {
  list-style: none;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  @include extend-container();
}

.page-user #profile-written-works ul li {
  max-width: 24rem;
  padding: 0 2rem 2rem 2rem;
  margin-bottom: 5rem;
  text-align: center;
  position: relative;
}

.page-user #profile-written-works ul li h4 {
  margin: 1.5rem 0;
}

.page-user #profile-written-works ul li a {
  @extend .link-arrow;
  position: absolute;
  bottom: 0px;
  right: 0;
  left: 0;
  text-align: center;
}

.page-user #profile-written-works ul li a:after {
  content: "\e90c";
  @extend .icon;
}

.page-user .view-blogs-by-instructor {
  padding: 5rem 0 6rem 0;
}

.page-user .view-blogs-by-instructor h2 {
  @include media-breakpoint-down(md) {
    text-align: center;
    margin-top: 3rem;
  }
}

// This starts the styling for the regular user profile.
.page-user .profile-membership-level {
  background-color: $gray;
  @include extend-container();
  text-align: center;
  color: #fff;
  padding: 3rem 0;
}

.page-user .profile-membership-level h3 {
  color: #fff;
  border: none;
}

.page-user .profile-membership-level .membership-expiration {
  font-size: 2rem;
  color: #fff;
  margin-bottom: 2rem;
}

.page-user .profile-information-right ul {
  list-style: none;
}

.page-user .profile-information-right ul li {
  width: 100%;
  border-bottom: 1px solid $gray;
  padding: .6rem;

  :first-child {
    padding-top: 0;
  }
}

.page-user .profile-information-right ul li .profile-information-label {
  width: 50%;
  display: inline-block;
  color: $gray;
  vertical-align: top;
}

.page-user .profile-information-right ul li .address-information {
  display: inline-block;
  width: 48%;
}

.page-user .profile-information-right .profile-edit-information {
  float: right;
  color: $gray;
}

.page-user .profile-information-right .profile-edit-information span {
  padding-left: 1.5rem;
}

.page-user .profile-user-notifications .flag {
  display: block;
  padding: .80rem 2.5rem;
  border: 1px solid $brand-blue;
  color: #000;
  border-radius: 2rem;
  margin-top: 1.5rem;
  text-transform: capitalize;
  text-align: left;
  font-weight: 300;
  :after {
    @extend %icon;
    content: "\e900";
    float: right;
    font-weight: 300;
    padding-top: 1rem;
  }
}

.page-user .profile-user-notifications .flag-remove-all {
  width: 100%;
  display: block;
  text-align: right;
}

.page-user .profile-user-notifications .profile-user-notifications-inner {
  @include extend-container();
  @include media-breakpoint-up(lg) {
    max-width: 840px;
    padding-left: 0px;
  }
}

.page-user .profile-information .profile-information-user {
  @include extend-container();
  @include media-breakpoint-up(lg) {
    max-width: 840px;
    padding-left: 0px;
  }
}

.page-user .profile-information h2 {
  font-weight: 300;
  margin-bottom: 1.5rem;
}

.page-user {
  #main {
    margin-top: 2em;
    margin-bottom: 2em;
  }
  .membership-expired a {
    color: #FFF;
    text-decoration: underline;
  }
}
