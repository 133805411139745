h1.title {
  line-height: 1.125;
  @include media-breakpoint-down(sm) {
    font-size: 3rem;
  }
}

.node-type-structured-content,
.node-type-lit-fest-landing-page,
.node-type-lit-fest-internal-page,
.node-type-basic-page,
.node-type-membership,
.page-workshops,
.view-session,
.page-taxonomy-term,
.node-type-passes,
.page-about-staff,
.page-search-node ,
.page-instructors {
  .title-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 4rem;
    padding: 4rem 2rem;
    height: auto;
    background: url(../images/default-hero-all.jpg) no-repeat;
    background-size: cover;
    text-align: center;
    @include media-breakpoint-up(lg) {
      height: 235px;
      margin-bottom: 6rem;
    }
  }
}

.page-taxonomy-term-3,
.page-blogs,
.page-blog-search,
.node-type-blog {
  .title-wrapper {
    background-image: url(../images/default-hero-blog.jpg);
  }
}

