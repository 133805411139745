.page-contact main {
  @include extend-container-max-width-full();
}

.page-contact .region-content form > div {
  @include media-breakpoint-up(lg) {
    display: flex;
  }
}

div#contact-block {
  padding: 2rem 2rem 6rem 2rem;
  background: url(../images/login-bg.jpg) no-repeat;
  background-size: cover;
  @include media-breakpoint-up(lg) {
    width: 50%;
  }
}

div#contact-form {
  padding: 2rem 2rem 4rem 2rem;
  background-color: $brand-blue;
  @include media-breakpoint-up(lg) {
    width: 50%;
  }
}

.contact-block {
  width: 100%;
  @include media-breakpoint-up(lg) {
    float: right;
  }
}

.contact-block h3 {
  color: #000;
}

.contact-block a {
  width: 100%;
}

.contact-block h3 {
  margin-top: 5rem;
}

.contact-form {
  width: 100%;
  @include media-breakpoint-up(lg) {
    float: left;
  }
}

.contact-form label, .contact-form h2, .contact-form p {
  color: #fff;
}

.contact-form .form-submit {
  width: 100%;
}

.contact-form .form-select {
  color: $brand-blue;
}

.contact-form p, .contact-block p {
  margin-bottom: 1rem;
}

.contact-block,
.contact-form {
  max-width: 70rem;
  margin: 0 auto;
  @include media-breakpoint-up(lg) {
    padding: 5rem;
  }
  @include media-breakpoint-up(xl) {
    padding: 10rem;
  }
}

.block--contact-info {
  padding: 10rem 0;
}

.contact-info-block-information-wrapper {
  @include extend-container();
}

.block--contact-info .content .contact-info-block-information {
  display: flex;
  flex-wrap: wrap;
}

.block--contact-info .content h3 {
  margin-bottom: 4rem;
}

.block--contact-info .content .contact-info-column {
  width: 31rem;
}

.block--contact-info ul {
  list-style-type: none;
  padding-left: 0;
}
