.header {
  display: inline-block;
  width: 100%;
  border-top: 3.6rem solid $gray;
  @include media-breakpoint-up(lg) {
    border-top: 0;
  }
}

.header-inner {
  position: relative;
  padding-top: 0.8rem;
  @include media-breakpoint-up(lg) {
    padding: 1.5rem 0 .9rem 0;
  }
}

.logo {
  float: left;
  width: 200px;
  @include media-breakpoint-up(lg) {
    width: 260px;
  }
  @include media-breakpoint-up(lg) {
    width: 317px;
  }
}

.logo img {
  float: left;
  width: 100%;
  height: auto;
}

#block-menu-menu-utility-navigation {
  position: absolute;
  top: -3.5rem;
  left: .5rem;
  font-family: $font-family-halis;
  @include media-breakpoint-up(lg) {
    position: static;
    top: auto;
    left: auto;
  }
  @include media-breakpoint-up(lg) {
    float: right;
    margin-right: 6rem;
    padding-top: 2.9rem;
  }
  @include media-breakpoint-up(xl) {
    margin-right: 10rem;
  }
}

#block-menu-menu-utility-navigation li {
  float: left;
  text-transform: uppercase;
  margin: 0 .75rem;
  @include media-breakpoint-up(lg) {
    margin: 0 2rem;
  }
}

#block-menu-menu-utility-navigation li a {
  font-size: 1.4rem;
  letter-spacing: -.1rem;
  font-weight: 400;
  color: #fff;
  @include media-breakpoint-up(sm) {
    font-size: 1.6rem;
    letter-spacing: normal;
  }
  @include media-breakpoint-up(lg) {
    color: $gray;
  }
  @include media-breakpoint-up(lg) {
    font-size: 1.8rem;
  }
}

#block-menu-menu-logged-in-user-menu {
  position: absolute;
  top: -3.5rem;
  right: .5rem;
  font-family: $font-family-halis;
  @include media-breakpoint-up(md) {
    right: auto;
    left: 26rem;
  }
  @include media-breakpoint-up(lg) {
    top: 0;
    right: .5rem;
    left: auto;
  }
  @include media-breakpoint-up(xl) {
    margin-right: 10rem;
  }
}

#block-menu-menu-logged-in-user-menu li {
  float: left;
  text-transform: uppercase;
  margin: 0 .75rem;
  @include media-breakpoint-up(lg) {
    margin: 0 1rem;
  }
}

#block-menu-menu-logged-in-user-menu li a {
  font-size: 1.4rem;
  letter-spacing: -.1rem;
  font-weight: 400;
  color: #fff;
  @include media-breakpoint-up(sm) {
    font-size: 1.6rem;
    letter-spacing: normal;
  }
  @include media-breakpoint-up(lg) {
    color: $brand-blue;
    font-size: 1.6rem;
  }
}

#block-menu-menu-social-media-menu {
  position: absolute;
  top: -3.1rem;
  right: .5rem;
  @include media-breakpoint-up(lg) {
    position: static;
    top: auto;
    right: auto;
    float: right;
    padding-top: 2.7rem;
  }
}

#block-menu-menu-social-media-menu li {
  float: left;
  margin: 0 .5rem;
}

#block-menu-menu-social-media-menu li a {
  display: inline-block;
  color: #fff;
  &:before {
    height: 2.6rem;
    width: 2.6rem;
    font-size: 1.3rem;
    background-color: $brand-blue;
    border-color: $brand-blue;
  }
  @include media-breakpoint-up(lg) {
    color: $brand-blue;
    &:before {
      height: 3.6rem;
      width: 3.6rem;
      font-size: 1.7rem;
      background-color: transparent;
      color: $brand-blue;
    }
  }
}

#block-search-form {
  position: absolute;
  right: 2rem;
  top: 1.4rem;
  .element-invisible {
    display: none;
  }
  @include media-breakpoint-down(sm) {
    display: none;
  }
  @include media-breakpoint-up(lg) {
    top: 1.9rem;
  }
}