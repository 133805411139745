.node-type-passes .region-content {
  @include extend-container();
  margin-bottom: 8rem;
}

.node-type-passes .field-commerce-price {
  margin-bottom: 2rem;
  font-size: 4rem;
  font-weight: bold;
}

.node-type-passes .field-type-commerce-price .field-label {
  font-weight: normal;
}

.node-type-passes .field-type-commerce-price .field-items {
  font-size: 4rem;
  font-weight: bold;
}

.node-type-passes .commerce-add-to-cart .select-style {
  @include media-breakpoint-up(md) {
    width: 50rem;
  }
}
