.footer {
  box-shadow: 0px -2px 17px 0px rgba(94,94,94, 0.37);
}

.footer-flex {
  @include media-breakpoint-up(md) {
    display: flex;
  }
}

.flex-item {
  @include media-breakpoint-up(md) {
    flex-direction: row;
    width: 50%;
  }
}

.footer-action-links-wrapper {
  background: url(../images/footer-bg.jpg) no-repeat;
  background-size: cover;
  @include media-breakpoint-up(md) {
    order: 2;
  }
}

.footer-action-links-wrapper-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: 30rem;
  padding: 3rem;
  text-align: center;
  @include media-breakpoint-only(lg) {
    min-height: 20rem;
  }
  @include media-breakpoint-up(xl) {
    float: left;
    width: 680px;
    padding: 7rem 4rem 5rem 4rem;
  }
}

.footer-action-links-wrapper-inner li {
  padding-top: 2rem;
  padding-bottom: 2rem;
  @include media-breakpoint-up(xl) {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}

.footer-action-links-wrapper-inner .arrow-right-icon {
  @include media-breakpoint-up(md) {
    font-size: 3rem;
  }
  @include media-breakpoint-up(xl) {
    font-size: 4rem;
  }
}

.footer-menu-wrapper {
  display: inline-block;
  width: 100%;
  background-color: $brand-blue;
  color: #fff;
  @include media-breakpoint-up(md) {
    order: 1;
    width: 50%;
  }
}

.footer-menu-wrapper a {
  color: #fff;
}

.footer-menu-wrapper-inner {
  width: 260px;
  margin: 0 auto;
  padding: 4rem 2rem;
  font-family: $font-family-halis;
  @include media-breakpoint-only(sm) {
    display: inline-block;
    width: 100%;
  }
  @include media-breakpoint-up(lg) {
    display: inline-block;
    width: 100%;
    padding-left: 3rem;
    padding-right: 3rem;
  }
  @include media-breakpoint-up(xl) {
    float: right;
    width: 680px;
    padding: 7rem 4rem 5rem 4rem;
  }
}

.region-footer-menu .expanded {
  @include media-breakpoint-up(xl) {
    float: left;
    width: 33%;
  }
}

.region-footer-menu {
  @include media-breakpoint-only(sm) {
    float: left;
    width: 50%;
    margin-top: 1.5rem;
    padding-right: 4%;
  }
  @include media-breakpoint-up(lg) {
    float: left;
    width: 50%;
    margin-top: 1.5rem;
    padding-right: 4%;
  }
  @include media-breakpoint-up(xl) {
    width: 100%;
  }
}

.stay-informed {
  @include media-breakpoint-only(sm) {
    float: right;
    width: 20rem;
  }
  @include media-breakpoint-up(lg) {
    float: right;
    width: 20rem;
  }
  @include media-breakpoint-up(xl) {
    float: left;
    width: 50%;
    margin-top: 4rem;
  }
}

.stay-informed .btn {
  padding: .5rem 0;
  border-width: 2px;
  font-size: 1.6rem;
  font-weight: 600;
  width: 100%;
  &:before {
    @extend %icon;
    content: '\e90f';
    margin-right: 1rem;
  }
  @include media-breakpoint-up(xl) {
    width: 25rem;
    padding: 0;
    border-width: 4px;
    font-size: 2rem;
  }
}

.footer-social-menu {
  margin-top: 3rem;
  @include media-breakpoint-only(sm) {
    float: right;
    clear: right;
    width: 20rem;
  }
  @include media-breakpoint-up(lg) {
    float: right;
    clear: right;
    width: 20rem;
  }
  @include media-breakpoint-up(xl) {
    float: left;
    width: 50%;
    margin-top: 11rem;
  }
}

.nolink {
  position: relative;
  display: block;
  width: 100%;
  border-bottom: 3px solid #fff;
  margin-bottom: 3rem;
  text-transform: uppercase;
  font-weight: 600;
  &:after {
    @extend %icon;
    content: '\e904';
    font-size: 3.2rem;
    float: right;
  }
  @include media-breakpoint-up(xl) {
    border-bottom: 0;
    &:before {
      content: '';
      position: absolute;
      top: 3.2rem;
      left: 0;
      height: 3px;
      width: 3rem;
      background-color: #fff;
    }
    &:after {
      display: none;
    }
  }
}

.footer-menu-wrapper-inner h2 {
  position: relative;
  display: inline-block;
  font-size: 2rem;
  width: 100%;
  margin-bottom: 2rem;
  text-transform: uppercase;
  font-weight: 600;
  &:before {
    content: '';
    position: absolute;
    top: 4.2rem;
    left: 0;
    height: 3px;
    width: 3rem;
    background-color: #fff;
  }
  @include media-breakpoint-up(xl) {
    border-bottom: 0;
  }
}

// Using large max-height, so transition can work without a fixed height.
.nolink + ul {
  max-height: 0;
  overflow: hidden;
  transition: max-height .3s ease-in-out;
  font-size: 1.7rem;
  cursor: pointer;
  @include media-breakpoint-up(xl) {
    height: auto;
    max-height: 500px;
    cursor: default;
  }
}

.nolink + ul.footer-menu-open {
  max-height: 500px;
}

.footer-social-menu > .nav {
  display: flex;
  justify-content: center;
}

.facebook-icon,
.twitter-icon,
.youtube-icon,
.soundcloud-icon,
.instagram-icon {
  float: left;
  margin: 0 0.5rem;
  &:before {
    height: 4rem;
    width: 4rem;
    border: 2px solid #fff;
    font-size: 2rem;
    font-family: 'IcoMoon-Free-New'!important;
  }
  &:hover {
    &:before {
      border-color: #fff;
    }
  }
  @include media-breakpoint-up(xl) {
    margin: 0 1rem;
    &:before {
      height: 5.2rem;
      width: 5.2rem;
      border-width: 4px;
      font-size: 2.6rem;
    }
  }
}

.utility-copyright {
  width: 80%;
  margin: 1rem auto 0 auto;
  padding-top: 1.2rem;
  border-top: 1px solid #7a7a7a;
  line-height: 1.25;
  text-align: center;
  font-size: 1.6rem;
  @include media-breakpoint-up(xl) {
    float: right;
    width: 70%;
    padding: 0;
    margin: .5rem 0 0 0;
    border: 0;
    text-align: right;
  }
}

.footer-utility-wrapper {
  display: inline-block;
  width: 100%;
  background-color: $gray;
}

.footer-utility-wrapper-inner {
  @include extend-container();
}

.region-footer-utility {
  max-width: 360px;
  margin: 0 auto;
  @include media-breakpoint-up(xl) {
    float: left;
    width: 30%;
    margin: 0;
  }
}

.footer-utility-wrapper,
.footer-utility-wrapper a {
  color: #fff;
}

.footer-utility-wrapper-inner {
  padding: 2rem;
}

#block-menu-menu-footer-utility-menu .nav {
  display: flex;
  justify-content: space-between;
  font-size: 1.6rem;
}
