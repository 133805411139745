// Use when .container class cannot be applied to markup.
@mixin extend-container() {
  @include make-container();
  @include make-container-max-widths();
}

@mixin extend-container-full-width() {
  @include make-container();
  width: 100%;
}

@mixin extend-container-max-width-full() {
  max-width: 100%;
}
