// Variables - Base
//
// define / override bootstrap variables for base elements

// General variable structure
//
// Variable format should follow the `$component-modifier-state-property` order.


// Colors
//
// Grayscale and brand colors for use across Bootstrap.

$gray-dark:                 #373a3c;
$gray:                      #545759;
$gray-light:                #ddd;
$gray-lighter:              #ebebeb;
$gray-lightest:             #f3f3f3;

$brand-primary:             #009aa5;
$brand-success:             #5cb85c;
$brand-info:                #5bc0de;
$brand-warning:             #f0ad4e;
$brand-danger:              #d9534f;

$brand-orange:              #f26c23;
$brand-blue:                $brand-primary;
$brand-dark-blue:           #009AA5;
$brand-dark-blue-2:         #006a72;
$brand-text-gray:           #373536;


// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-flex:               false;
$enable-rounded:            false;
$enable-shadows:            false;
$enable-gradients:          false;
$enable-transitions:        false;
$enable-hover-media-query:  false;
$enable-grid-classes:       false;


// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.

$spacer:                     1rem;
$spacer-x:                   $spacer;
$spacer-y:                   $spacer;
$border-width:               1px;


// Body
//
// Settings for the `<body>` element.

$body-bg:                    #fff;
$body-color:                 $gray-dark;

// Grid breakpoints
//
// Define the minimum and maximum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  // Extra small screen / phone
  xs: 0,
  // Small screen / phone
  sm: 544px,
  // Medium screen / tablet
  md: 768px,
  // Large screen / desktop
  lg: 992px,
  // Extra large screen / wide desktop
  xl: 1360px
);


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 576px,
  md: 720px,
  lg: 940px,
  xl: 1280px
);


// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns:               12;
$grid-gutter-width:          2rem; // 20px


// Typography
//
// Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif:     Calibri, Helvetica, Arial, sans-serif;
$font-family-serif:          Georgia, "Times New Roman", Times, serif;
$font-family-monospace:      Menlo, Monaco, Consolas, "Courier New", monospace;
$font-family-halis:          "Halis R", Helvetica, Arial, sans-serif;
$font-family-base:           $font-family-sans-serif;

// Pixel value used to responsively scale all typography. Applied to the `<html>` element.
$font-size-root:             10px;

$font-size-base:             1.6rem;
$font-size-lg:               2rem;
$font-size-sm:               1.4rem;
$font-size-xs:               1rem;

$display1-size:               6rem;
$display2-size:               5.5rem;
$display3-size:               4.5rem;
$display4-size:               3.5rem;

$display1-weight:             300;
$display2-weight:             300;
$display3-weight:             300;
$display4-weight:             300;

$line-height:                1.5;

$headings-margin-bottom:     ($spacer / 2);
$headings-font-family:       inherit;
$headings-font-weight:       500;
$headings-line-height:       1.1;
$headings-color:             inherit;

$lead-font-size:             1.25rem;
$lead-font-weight:           300;

$text-muted:                  $gray-light;

$abbr-border-color:           $gray-light;

$blockquote-small-color:      $gray-light;
$blockquote-font-size:        ($font-size-base * 1.25);
$blockquote-border-color:     $gray-lighter;

$hr-border-color:             rgba(0,0,0,.1);
$hr-border-width:             $border-width;


// Components
//
// Define common padding and border radius sizes and more. Values based on 14px text and 1.428 line-height (~20px to start).

$line-height-lg:         (4 / 3);
$line-height-sm:         1.5;

$border-radius:          .25rem;
$border-radius-lg:       .3rem;
$border-radius-sm:       .2rem;

$component-active-color: #fff;
$component-active-bg:    $brand-primary;

$caret-width:            .3em;
$caret-width-lg:         $caret-width;


// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

$table-cell-padding:            .75rem;
$table-sm-cell-padding:         .3rem;

$table-bg:                      transparent;
$table-bg-accent:               #f9f9f9;
$table-bg-hover:                #f5f5f5;
$table-bg-active:               $table-bg-hover;

$table-border-color:            $gray-lighter;


// Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.
//
// Note: These variables are not generated into the Customizer.

$zindex-navbar:            1000;
$zindex-dropdown:          1000;
$zindex-popover:           1060;
$zindex-tooltip:           1070;
$zindex-navbar-fixed:      1030;
$zindex-navbar-sticky:     1030;
$zindex-modal-bg:          1040;
$zindex-modal:             1050;


// Image thumbnails
$thumbnail-padding:           .25rem;
$thumbnail-bg:                $body-bg;
$thumbnail-border:            #ddd;
$thumbnail-border-radius:     $border-radius;


// Code

$code-color:                  #bd4147;
$code-bg:                     #f7f7f9;

$kbd-color:                   #fff;
$kbd-bg:                      #333;

$pre-bg:                      #f7f7f9;
$pre-color:                   $gray-dark;
$pre-border-color:            #ccc;
$pre-scrollable-max-height:   340px;
