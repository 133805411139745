.node-type-blog .region-content {
  @include extend-container();
}

.blog-metadata {
  background-color: $gray;
  margin-bottom: 8rem;
  padding: 2rem 0;
}

.blog-metadata a {
  color: #fff;
}

.blog-metadata ul {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  margin-bottom: 0;
  list-style-type: none;
  color: #fff;
  @include media-breakpoint-up(lg) {
    flex-direction: row;
  }
}

.blog-metadata li {
  width: 100%;
  padding: 1rem;
  border-bottom: 1px solid #7a7a7a;
  text-align: center;
  @include media-breakpoint-up(lg) {
    width: 33%;
    padding: 0;
    border-bottom: 0;
    border-right: 1px solid #7a7a7a;
    text-align: center;
  }
  &:last-child {
    border: 0;
  }
}

.node-type-blog .field-name-body {
  @include media-breakpoint-up(md) {
    max-width: 60rem;
    margin: 0 auto;
  }
}

.node-type-blog .field-name-field-image {
  max-width: 60rem;
  margin: 0 auto 4rem auto;
}

.node-type-blog .field-name-field-image img {
  width: 100%;
  height: auto;
}

.node-type-blog .comment-wrapper {
  max-width: 60rem;
  margin: 0 auto;
}

.node-type-blog {
  .field-name-body iframe {
    width: 600px;
    height: 337px;
    margin: 2.25em 0;
  }
  .field-name-body p iframe {
    margin-bottom: 0;
  }
  @media (max-width: 767px) {
    .field-name-body iframe {
      width: 100%;
      height: 320px;
    }
  }
}
