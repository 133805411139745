// Begin metadata bar styles.
.session-metadata-bar {
  @include extend-container();
  margin-bottom: 10rem;
  padding: 2rem;
  background-color: $gray;
  color: #fff;
  @include media-breakpoint-up(md) {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    text-align: center;
  }
}

.session-metadata-bar .views-field {
  padding: 1rem;
  border-bottom: 1px solid #6b6c6d;
  @include media-breakpoint-only(md) {
    width: 50%;
  }
  @include media-breakpoint-up(lg) {
    width: 20%;
    border-bottom: 0;
    border-right: 1px solid #6b6c6d;
    &:last-child {
      border-right: 0;
    }
  }
}

.session-metadata-bar .views-label {
  display: block;
  text-transform: uppercase;
  font-weight: bold;
}
// End metadata bar styles.

// Begin description styles.
.session-description {
  @include extend-container();
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin-bottom: 10rem;
  @include media-breakpoint-up(lg) {
    max-width: 84rem;
    flex-direction: row;
    text-align: left;
  }
}

.session-description-image {
  @include media-breakpoint-up(md) {
    flex: 1 0 auto;
  }
}

.session-description-image-wrapper {
  position: relative;
  width: 100%;
  max-width: 375px;
  margin: 0 auto;
}

.session-description-image-wrapper img {
  width: 100%;
  height: auto;
}

.session-description-image-wrapper .views-field-field-tags {
  position: absolute;
  bottom: 0rem;
  left: 0;
  height: 3.4rem;
  display: flex;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  font-weight: 600;
  font-size: 1.6rem;
  border-top-right-radius: 2rem;
  border-bottom-right-radius: 2rem;
  background-color: $brand-orange;
  color: #fff;
  text-transform: uppercase;
}

.session-description-price-wrapper {
  margin-top: 3.4rem;
  font-family: $font-family-halis;
  color: $gray;
  @include media-breakpoint-up(lg) {
    display: flex;
    justify-content: space-around;
  }
}

.session-description-image .views-field-field-session-member-price,
.session-description-image .views-field-commerce-price {
  text-align: center;
  line-height: 1.25;
  font-size: 4rem;
  font-weight: 600;
}

.session-label-price {
  display: block;
  font-size: 2rem;
  font-weight: 300;
}

.session-description-text {
  @include media-breakpoint-up(lg) {
    padding-left: 5rem;
  }
}

.session-description-text p {
  margin-bottom: 1rem;
}
// End description styles.

// Begin register styles.
.session-register {
  @include extend-container();
  margin-bottom: 8rem;
  @include media-breakpoint-up(md) {
    width: 60rem;
    margin: 0 auto 8rem auto;
  }
}

.session-register h2 {
  font-size: 3rem;
  text-align: center;
}

.session-register .help-block,
.session-register .help-block small {
  display: inline-block;
  font-size: 1.6rem;
  line-height: 1.125;
}

.session-register .form-item {
  margin-top: 0;
}

.session-register .form-item-is-me {
  margin-bottom: 2rem;
}

.session-register .form-type-password {
  margin-bottom: 3rem;
}

.session-register .form-type-password .form-text {
  margin: 1rem 0;
}

.session-register .form-submit {
  float: right;
}

.session-register .views-field-ops {
  clear: both;
  display: inline-block;
  margin-top: 3rem;
}

.session-register-anonymous {
  margin: 2rem 0;
}
// End register styles.

// Begin profile callout styles.
.session-profile-callout {
  @include extend-container();
  display: flex;
  flex-direction: column;
  margin-bottom: 6rem;
  padding: 3.5rem;
  text-align: center;
  background: url(../images/session-profile-bg.jpg) no-repeat;
  background-size: cover;
  @include media-breakpoint-up(md) {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 10rem;
    text-align: left;
  }
}

.session-profile-callout .views-field-picture {
  margin-bottom: 2rem;
  @include media-breakpoint-up(md) {
    margin-bottom: 0;
    padding-right: 5rem;
  }
}

.session-profile-callout .views-field-picture img {
  border-radius: 50%;
}

.session-profile-callout .views-field-field-last-name {
  font-size: 2.6rem;
  font-weight: 600;
  text-transform: uppercase;
  font-family: $font-family-halis;
  color: #fff;
}

.session-profile-callout .views-field-field-last-name a {
  @extend .btn;
  @extend .btn-ghost-white;
  display: block;
  width: 24rem;
  margin-top: 2rem;
  @include media-breakpoint-down(sm) {
    margin: 2rem auto 0 auto;
  }
}
// End profile callout styles.

// Begin session schedule styles.
.session-schedule {
  @include extend-container();
  text-align: center;
  @include media-breakpoint-up(md) {
    width: 70rem;
    margin: 0 auto;
    text-align: left;
  }
  @include media-breakpoint-up(lg) {
    width: 84rem;
  }
}

.session-schedule h2 {
  font-size: 3rem;
}

.session-schedule-wrapper {
  margin-bottom: 10rem;
  @include media-breakpoint-up(md) {
    display: flex;
  }
}

.session-schedule-wrapper .session-schedule-details {
  margin-bottom: 2rem;
  @include media-breakpoint-up(md) {
    width: 50%;
    margin-bottom: 0;
  }
  @include media-breakpoint-up(lg) {
    width: 335px;
  }
}

.session-schedule-wrapper .views-field-field-venue-google-map {
  @include media-breakpoint-up(md) {
    width: 50%;
  }
  @include media-breakpoint-up(lg) {
    width: 67%;
  }
}

.views-field-schedule .field-content span {
  display: block;
}

.views-field-field-venue-google-map iframe {
  width: 100%;
  height: 28rem;
  border: 1px solid $gray-light;
}
// End session schedule styles.
