#popup-bg {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
}
#popup-content {
  background: #FFF;
  width: 100%;
  max-width: 520px;
  position: fixed;
  top: 50px;
  z-index: 101;
  left: 50%;
  margin-left: -260px;
  padding: 30px;
  box-shadow: 0 0 20px #000;
  border-radius: 10px;
  p {
    margin: 0;
  }
}
#popup-title {
  font-size: 30px;
  text-align: center;
  margin-bottom: 20px;
}
#popup-actions {
  margin-top: 30px;
  .left {
    float: left;
  }
  .right {
    float: right;
  }
  .btn {
    padding: 0 1em;
    font-size: 14px;
  }
}
@media (max-width: 540px) {
  #popup-content {
    margin-left: 0;
    left: 10px;
    right: 10px;
    width: auto;
  }
  #popup-title {
    font-size: 2.2rem;
    line-height: 3rem;
  }
  #popup-actions {
    .btn {
      width: 100%;
    }
    .left {
      margin-bottom: 5px;
    }
  }
}