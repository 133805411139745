// Begin staggered styles.
.group-staggered-content-wrapper {
  @include extend-container();
}

.group-staggered-content-wrapper a {
  @extend .btn;
  @extend .btn-ghost-blue;
  @include media-breakpoint-up(lg) {
    float: left;
  }
}

.group-staggered-content-wrapper .field-name-field-file {
  clear: both;
}

.group-staggered-content-wrapper .field-name-field-file a {
  margin-top: 2rem;
}

.group-staggered-content-wrapper .field-name-field-file img {
  display: none;
}

.group-staggered-content-wrapper .field-name-field-additional-text {
  margin-top: 2rem;
  @include media-breakpoint-up(lg) {
    float: right;
  }
}

.field-name-field-staggered-content > .field-items > .field-item {
  text-align: center;
  margin-bottom: 8rem;
  @include media-breakpoint-up(md) {
    display: flex;
    width: 720px;
    margin: 0 auto 12rem auto;
    text-align: left;
  }
  @include media-breakpoint-up(lg) {
    width: 900px;
  }
}
.field-name-field-staggered-content > .field-items > .field-item .field-name-field-image {
  @include media-breakpoint-up(md) {
    flex: 1 0 auto;
  }
}

.field-name-field-staggered-content > .field-items > .field-item.odd .field-name-field-image {
  order: 2;
  @include media-breakpoint-up(md) {
    margin-left: 5rem;
    flex: 1 0 auto;
  }
}

.group-staggered-content-wrapper .field-name-field-image {
  margin-bottom: 3rem;
  @include media-breakpoint-up(md) {
    margin-right: 5rem;
    margin-bottom: 0;
  }
}

.group-staggered-content-wrapper .field-name-field-long-text {
  margin-bottom: 2rem;
}
// End staggered styles.
