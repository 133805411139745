.page-cart .region-content,
.page-checkout .region-content {
  @include extend-container();
}

.commerce-line-item-views-form input.delete-line-item {
  width: 100%;
  line-height: 1;
}

.commerce-line-item-actions .form-submit {
  width: 100%;
  margin-bottom: 1rem;
  @include media-breakpoint-up(md) {
    width: auto;
    margin-left: 1rem;
  }
}

#edit-lighthousewriters-commerce-add-membership-submit {
  display: block;
  margin-top: 1rem;
  line-height: 1;
  @include media-breakpoint-up(xl) {
    float: right;
    margin-top: 0;
  }
}

.commerce_coupon .form-submit {
  line-height: 1;
}

.page-checkout fieldset {
  margin-top: 3rem;
}

.page-checkout .form-item {
  margin-top: 1rem;
}

.page-checkout .form-group {
  margin-bottom: 0;
}

.page-checkout .button-operator {
  margin-left: 1rem;
}

.page-checkout .checkout-buttons .checkout-cancel,
.page-checkout .checkout-buttons .checkout-back {
  color: $brand-blue;
  text-decoration: underline;
  &:hover {
    color: $brand-blue;
    text-decoration: none;
  }
}

.page-checkout .help-block {
  margin-top: 1rem;
  font-size: 1.4rem;
  line-height: 1.25;
}

.page-checkout .form-radios {
  @include custom-radio();
}

.page-checkout .commerce-credit-card-expiration {
  margin-bottom: 1rem;
  display: inline-block;
}

.page-checkout .form-item-commerce-payment-payment-details-credit-card-exp-month > label {
  @extend %sr-only-override;
  line-height: 1;
}

.page-checkout .commerce-credit-card-expiration .form-item {
  float: left;
  width: 14rem;
}

.page-checkout .form-item-commerce-payment-payment-details-credit-card-exp-year {
  margin-top: 4rem;
}

.page-checkout .commerce-credit-card-expiration .commerce-month-year-divider {
  float: left;
  margin: 5rem 1rem 0 1rem;
}

.form-item-commerce-payment-payment-details-credit-card-code {
  width: 14rem;
}

#edit-customer-profile-billing-cancel-button,
#edit-customer-profile-billing-edit-button {
  margin-top: 1rem;
  line-height: 1;
}

form#commerce-checkout-form-review {
  label[for="edit-commerce-payment-payment-method-commerce-stripecommerce-payment-commerce-stripe"] {
    display: none;
  }
}

.page-cart,
.page-checkout,
.page-checkout-review {
  #page-title {
    margin-top: 1em;
    margin-bottom: 1em;
    text-align: center;
    font-size: 4rem;
  }
  .region-content {
    margin-bottom: 3em;
    form .fieldset-legend {
      color: inherit;
    }
  }
}
